import { Guid } from "guid-typescript";
import { start } from "repl";
import { AuthService } from "./AuthService";
export interface Activity {
  alert_counts: Count[];
  inference_counts: Count[];
}
export interface Count {
  epoch_msecs: number;
  count: number;
}
export interface UpdateWebChannelResults {
  success: boolean;
  msg: string;
}
export interface WebChannelResults {
  success: boolean;
  data: WebChannelRoles;
}
export interface WebChannelRoles {
  alert_channel_ids: string[];
  other_web_end_point_roles: string[];
  web_end_point_roles: string[];
}
export interface IpValidity {
  key: string;
  index: number;
  type: string;
  netmask: string;
  valid: boolean;
}
export interface HealthTimelineCount {
  epoch_msecs: number;
  doc_count: number;
  site_count: number;
  indicators: { [key: string]: number };
}
export interface SensorHealthTimelineCount {
  epoch_msecs: number;
  doc_count: number;
  sensor_count: number;
  sensors: { [key: string]: { [key: string]: number } };
}

export interface AlertConfig {
  enabled: boolean;
  alert_config_id: number;
  alert_channel_id: number;
  sensor_id: number;
  class_label: string;
  alert_type: string;
  sensor_id_str: string;
  human_name: string;
  valid_days_and_times: string;
  exact_times?: string;
  advanced: boolean;
}

export interface AlertChannel {
  alert_channel_id: number;
  name: string;
  org_id: number;
  org_name: string;
  site_id: number;
  created: string;
  description: string;
  timezone_str: string;
  locale: string;
  meta: { [key: string]: any };
  type: string;
  default_confidence_threshold: number;
  sop: string;
  emails: MultiEmail[];
  site_name: string;
  arming_status: { [key: string]: any };
}
export interface AlertChannelsForOrg {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  alert_channels: AlertChannel[];
}
export interface AlertEndPoint {
  alert_channel_end_point_id: number;
  enabled: boolean;
  name: string;
  alert_channel_id: number;
  meta: { [key: string]: any };
  end_point_type: string;
  type: string;
  users: User[];
}
export interface AlertEndPointDef {
  alert_channel_end_point_def_id: number;
  enabled: boolean;
  name: string;
  definition: { [key: string]: any };
  meta: string;
  end_point_type: string;
}
export interface AuthResult {
  orgs: OrgSelect[];
  access_token: string;
  refresh_token: string;
  success: boolean;
  msg: string;
  code: number;
}
export interface BillingRate {
  id: number;
  product: string;
  classifier_id: number;
  tier_id: number;
  tier: string;
  classifier: string;
  meta: string;
  rate_info: string;
  data_origin: DataOrigin;
  public_product_name: string;
}
export interface Notes {
  success: boolean;
  predefined_notes: {
    alert_web_interface_notes: string[];
  };
}
export interface NotesProps {
  alert_web_interface_notes: string[];
}
export interface DataOrigin {
  data_origin_id: number;
  name: string;
  description: string;
  default_config: string;
  meta: string;
}
export interface BillingRatesForOrg {
  success: boolean;
  org_billing_rates: OrgBillingRate[];
}
export interface CanvasImage {
  width: number;
  height: number;
  stringb64: string;
}
export interface Classifier {
  key: number;
  name: string;
  enabled: boolean;
  description: string;
  rest_api_urls: string;
  class_labels: string[];
  meta: string;
  sensor_defaults: { [key: string]: any };
}
export interface DataOriginDefaults {
  success: boolean;
  data_origin: DataOriginElements;
}
export interface DataOriginElements {
  id: number;
  name: string;
  sensor_defaults: { [key: string]: any };
}
export interface CreateAlertChannelResult {
  success: boolean;
  msg: string;
  alert_channel_id: number;
}
export interface CreateAlertConfigResult {
  success: boolean;
  msg: string;
  alert_channel_id: number;
}
export interface AddBillingProfileResult {
  success: boolean;
  msg: string;
}
export interface addBillingOrgResult {
  success: boolean;
  msg: string;
}
export interface registrationResult {
  success: boolean;
  msg: string;
  registration_data: {
    registration_id: number;
    password: string;
  };
}
export interface addOrgToImmixResult {
  success: boolean;
  msg: {
    immix_group_password: string;
    immix_billing_rate_id: number;
    immix_locale: string;
    immix_timezone_str: string;
  };
}
export interface exportBillingRunResult {
  success: boolean;
  msg: string;
  data: { [key: string]: any }[];
}

export interface GetSentinelResult {
  success: boolean;
  msg: {
    token: string;
    meta: { [key: string]: any };
  };
}
export interface editBillingRunResult {
  success: boolean;
  msg: string;
  billing_run_id: number;
}
export interface ListBillingRunTypesResult {
  success: boolean;
  billing_run_types: listBillingRunTypesItems[];
}
export interface listBillingRunTypesItems {
  id: number;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: string;
}
export interface listBillingRatesEndPoints {
  success: boolean;
  org_billing_rates: string[];
}
export interface listBillingProductOrgs {
  success: boolean;
  billing_orgs: billingProductOrgsItems[];
}
export interface billingProductOrgsItems {
  id: number;
  name: string;
  org_level: number;
  enabled: boolean;
  created: string;
  meta: { [key: string]: any };
}

export interface listOrgBillingRates {
  success: boolean;
  billing_rates: listOrgBillingRatesItems[];
}
export interface listOrgBillingRatesItems {
  id: number;
  enabled_for_new_sites: boolean;
  org_id: number;
  billing_rate_id: number;
  classifier: string;
  product: string;
  tier: string;
  data_origin_id: number;
  data_origin_name: string;
  max_sites: number;
  max_hubs: number;
  max_sensors: number;
  max_sensors_per_site: number;
}
export interface listBillingRates {
  success: boolean;
  billing_rates: listBillingRatesItems[];
}
export interface listBillingRateSupportItems {
  success: boolean;
  billing_rate_support_items: billing_rate_support_items;
}
export interface billing_rate_support_items {
  classifier_data: classifier_items[];
  data_origin_data: data_origin_items[];
  product_name_data: product_name_items[];
  tier_data: tier_items[];
}
export interface tier_items {
  tier: string;
  tier_id: number;
}
export interface product_name_items {
  product_name: string;
  product_name_id: number;
}
export interface data_origin_items {
  data_origin_name: string;
  data_origin_id: number;
}
export interface classifier_items {
  classifier: string;
  classifier_id: number;
}
export interface listClassLabels {
  success: boolean;
  class_labels: string[];
}
export interface listBillingRatesItems {
  id: number;
  classifier_id: number;
  classifier: string;
  tier_id: number;
  tier: string;
  product_name_id: number;
  group_count: number;
  product: string;
  meta: { [key: string]: any };
  data_origin: {
    data_origin_id: number;
    name: string;
    description: string;
    default_config: { [key: string]: any };
    meta: { [key: string]: any };
  };
  rate_info: rate_info;
  created: Date | string;
  enabled_site_count: number;
  public_product_name?: string;
}
export interface rate_info {
  currency: string;
  description: string;
  VAT_included: boolean;
  volume_bands: volume_bands_items[];
  billing_cycle: string;
  VAT_percentage: number;
}

export interface volume_bands_items {
  rate: number;
  lower: number;
  upper: number;
}

export interface listBillingResult {
  success: boolean;
  billing_profiles: listBillingItems[];
}
export interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}
export interface ListBillingRunsResult {
  success: boolean;
  billing_runs: listBillingRunsItems[];
}
export interface listBillingRunsItems {
  id: number;
  run_type_id: number;
  run_type: string;
  name: string;
  run_date: Date | string;
  description: string;
  rate_info: { [key: string]: any };
  run_username: string;
  status: string;
  meta: { [key: string]: any };
  created: Date | string;
}
export interface ListBillingProfileUsersResult {
  success: boolean;
  billing_profile_users: listBillingProfileUsersItems[];
}
export interface listBillingProfileUsersItems {
  billing_profile_user_id: number;
  user_id: number;
  username: string;
  first_name: string;
  last_name: string;
}
export interface ListBillingProfileOrgsResult {
  success: boolean;
  billing_profile_orgs: listBillingProfileOrgsItems[];
}
export interface listBillingProfileOrgsItems {
  id: number;
  enabled: boolean;
  profile_id: number;
  billing_org_id: number;
  billing_org_name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}
export interface ListHikCentralEntitiesResult {
  success: boolean;
  msg: hikCentralItems;
}
export interface hikCentralItems {
  code: string;
  msg: string;
  data: hikCentralData;
}
export interface hikCentralData {
  total: number;
  pageNo: number;
  pageSize: number;
  list: hikCentralListItems[];
}
export interface hikCentralListItems {
  cameraIndexCode: string;
  cameraName: string;
  capabilitySet: string;
  encodeDevIndexCode: string;
  recordType: string;
  recordLocation: string;
  regionIndexCode: string;
  siteIndexCode: string;
  status: string;
}
export interface CreateAlertEndPointResult {
  success: boolean;
  msg: string;
  alert_channel_end_point_id: number;
}
export interface CreateHubResult {
  success: boolean;
  msg: string;
  hub_id: number;
}
export interface CreateOrgResult {
  success: boolean;
  msg: string;
  org_id: number;
}
export interface CreateSensorResult {
  success: boolean;
  msg: string;
  sensor_id: number;
  added_to_hub: boolean;
  hub_msg: string;
}
export interface CreateNewSmtpPasswordResult {
  success: boolean;
  edge_enhanced_smtp_server: string;
  edge_enhanced_smtp_server_port: string;
  smtp_password: string;
}
export interface CreateSiteResult {
  success: boolean;
  msg: string;
  site_id: number;
}
export interface CreateSiteReportResult {
  success: boolean;
  msg: string;
  site_reporting_id: number;
}
export interface CreateReportGroupResult {
  success: boolean;
  msg: string;
  report_group_id: number;
}
export interface CreateUserResult {
  success: boolean;
  msg: string;
  user_id: number;
}
export interface CreateUserAlertChannelResult {
  success: boolean;
  msg: string;
}
export interface DeleteResult {
  success: boolean;
  msg: string;
}
export interface EntryPoint {
  user_org_role_id: number;
  org_id: number;
  org_name: string;
  role: string;
}
export interface EntryPointValues {
  key: string;
  value: any;
  type: string;
}
export interface EntryPointsForUser {
  success: boolean;
  user_entry_points: EntryPoint[];
}
export interface GetAlertConfig {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  alert_configs: AlertConfig[];
}
export interface GetAlertEndPoints {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  alert_endpoints: AlertEndPoint[];
  telegram_definition: AlertEndPointDef;
}
export interface GetAlertEndPointDefs {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  alert_channel_end_point_defs: AlertEndPointDef[];
}
export interface GetBillingRates {
  success: boolean;
  billing_rates: BillingRate[];
}
export interface GetClassifier {
  success: boolean;
  classifier: Classifier;
}
export interface GetConfigFiles {
  success: boolean;
  msg: string;
  camconfigx_files: { [key: string]: string[] };
}
export interface GetHubs {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  hubs: Hub[];
}
export interface GetSensors {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  sensors: Sensor[];
}
export interface GetLocale {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  locales: Locale[];
}
export interface GetLocaleList {
  success: boolean;
  locales: Locale[];
}
export interface GetResult {
  success: boolean;
  msg: string;
}
export interface GetReportGroups {
  success: boolean;
  msg: string;
  report_groups: ReportGroup[];
}
export interface GetSiteReports {
  site_reporting_configs: SiteReport[];
}
export interface AliveResult {
  success: boolean;
  alive: Alive;
}
export interface StatusResult {
  success: boolean;
  status: Status;
}
export interface StreamingUrlResult {
  success: boolean;
  base_url: Status;
}
export interface Status {
  last_conf_update_epoch_secs: number;
  success: boolean;
  sw_ver_hub_admin: string;
  sw_ver_hub_health: string;
  sw_ver_hub_rest_server: string;
  sw_ver_motioncam: string;
  sw_ver_update_conf: string;
}
export interface Alive {
  success: boolean;
  msg: string;
  sw_ver: string;
}
export interface GetMessageToken {
  success: boolean;
  msg: string;
  token: string;
}
export interface GetNetworkConfig {
  config: string;
}

export interface GetSites {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  sites: Site[];
}
export interface GetSnapShot {
  success: boolean;
  msg: string;
  image: CanvasImage;
}
export interface SnapshotFromServer {
  success: boolean;
  msg: CanvasImage;
  standard_image: CanvasImage;
  scale_factor: number;
}
export interface SMTPSnapshots {
  inference_images: inference_image[];
}
export interface inference_image {
  doc_id: string;
  elastic_index: string;
  created: string;
  image_url: string;
  classifier: string;
  vf_image_height: number;
  vf_image_width: number;
  site_id: number;
  sensor_id_str: string;
  improve_accuracy_flag: boolean;
}

export interface GetOrgOneLevel {
  success: boolean;
  org: Org;
}
export interface HealthForOrg {
  success: boolean;
  msg: HealthMsg;
}
export interface HealthHub {
  hub_id_str: string;
  site_health_indicator: string;
  msg_healthy: boolean;
  vpn_healthy: boolean;
}
export interface HealthMsg {
  sites: SiteHealth[];
}
export interface SiteHealth {
  health_indicator: string;
  site_id: number;
  sensors: SensorHealth[];
  hubs: HealthHub[];
}

export interface SensorHealth {
  sensor_overall_indicator: string;
  sensor_id: number;
  sensor_id_str: string;
}

export interface Hub {
  hub_id: number;
  hub_id_str: string;
  location: string;
  site_id: number;
  enabled: boolean;
  org_tree: number[];
  type: string;
  type_str: string;
  activated: HubActivationToken;
  shared: string;
}
export interface HubActivationToken {
  success: boolean;
  msg: string;
  vpn_key_loaded: boolean;
  message_server: string;
  activation_code: string;
  code_expiry: Date;
  hub_activated: Date | string;
}
export interface HubsList {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  hubs: Hub[];
}
export interface HubTokenDetail {
  success: boolean;
  msg: string;
  vpn_key_loaded: boolean;
  message_server: string;
  activation_code: string;
  code_expiry: Date;
  hub_activated: string;
}
export interface Identity {
  entry_org_id: number;
  username: string;
}
export interface CheckVersionResult {
  min_management_gui_ver: string;
  management_gui_ver_logout_required: boolean;
}

export interface Locale {
  key: number;
  locale: string;
  element: string;
  mapping: { [key: string]: string };
  meta: string;
}
export interface MainMenu {
  usereditor: boolean;
  navigator: boolean;
  reports: boolean;
  dashboard: boolean;
  insights: boolean;
  sites: boolean;
  profile: boolean;
  rulesets: boolean;
  billing: boolean;
  site_reports: boolean;
  tests: boolean;
  search: boolean;
  review: boolean;
  review3: boolean;
  alpr: boolean;
  bi: boolean;
}
export interface MultiEmail {
  key: Guid;
  email: string;
  parent_id: number;
}
export interface MultiNumber {
  value: number;
  parent_id: number;
}
export interface MultiString {
  value: string;
  parent_id: number;
}
export interface NewHubId {
  success: boolean;
  msg: string;
  new_hub_id_str: string;
}
export interface NetworkConfig {
  network: Network;
}
export interface Network {
  version: number;
  renderer: string;
  ethernets: { [key: string]: EthernetDetail };
}

export interface EthernetDetail {
  dhcp4: boolean;
  dhcp6: boolean;
  gateway4: string;
  addresses: string[];
  nameservers: NameServers;
}
export interface NameServers {
  addresses: string[];
}
export interface Org {
  org_id: number;
  org_name: string;
  org_tree: number[];
  org_level: number;
  enabled: boolean;
  sites: Site[];
  hubs: Hub[];
  orgs: Org[];
  type: string;
  created: string;
  sub_org_count: number;
  site_count: number;
  hub_count: number;
  org_tree_names?: string[];
  disabled_date?: string;
  // TODO: JASON
  // frontel_org?: boolean;
}
export interface OrgBillingRate {
  id: number;
  enabled_for_new_sites: boolean;
  org_id: number;
  billing_rate_id: number;
  product: string;
  classifier: string;
  tier: string;
  max_sites: number;
  max_hubs: number;
  max_streams: number;
  max_streams_per_site: number;
  rate_info: string;
  data_origin_name: string;
  public_product_name?: string;
}

export interface OrgSelect {
  id: number;
  name: string;
}
export interface OrgTreeCount {
  success: boolean;
  totals: { [key: number]: any };
}
export interface MoveSelect {
  id: number;
  name: string;
}
export interface OrgTree {
  tree: Tree;
  success: boolean;
}
export interface OrgsList {
  orgs: Org[];
  success: boolean;
}
export interface ReportGroup {
  id: number;
  org_id: number;
  org_name: number;
  name: string;
  recipients: string[];
  type: string;
}
export interface ReportGroupForTree {
  id: number;
  org_id: number;
  org_name: string;
  name: string;
}
export interface Role {
  id: number;
  role: string;
  description: string;
  end_point_type: string;
}
export interface RolesForEndpoint {
  success: boolean;
  roles: Role[];
}

export interface RegisteredUsersResult {
  success: boolean;
  msg: string;
  registration_data: {
    registration_details: RegisteredUser[];
    allowed_roles: string[];
  };
}

export interface RegisteredUser {
  id: number;
  company_name: string;
  first_name: string;
  last_name: string;
  email: string;
  contact_number: string;
  country: string;
  hear_about_us: string;
  ts_and_cs: boolean;
  processed: boolean;
  createdAt: Date | string;
}
export interface SiteReport {
  id: number;
  type: string;
  site_id: number;
  report_group_id: number;
  report_group_name: string;
  report_type: string;
  daily_utc_schedule: string[];
}
export interface Sensor {
  sensor_id: number;
  sensor_id_str: string;
  hub_id: number;
  site_id: number;
  default_rule: boolean;
  enabled: boolean;
  config: { [key: string]: any };
  snapshot: CanvasImage;
  thumbnail: CanvasImage;
  standard_image: CanvasImage;
  human_name: string;
  meta: { [key: string]: any };
  type: string;
  max_resolution: string;
  advanced_rules_enabled: boolean;
  environment: string;
  standard_image_scale_factor?: number;
  scale_factor?: number;
  snapshot_height?: number;
  snapshot_width?: number;
  ptz?: any;
}

export interface SensorsForSite {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  sensors: Sensor[];
}
export interface Site {
  site_id: number;
  name: string;
  site_name: string;
  enabled: boolean;
  billing_rate_id: number;
  org_tree: number[];
  type: string;
  product: string;
  data_origin: string;
  created: string;
  sensorcount: number;
  alertconfigcount: AlertconfigCount;
  hubcount: number;
  reportcount: number;
  meta: { [key: string]: any };
  site_health: { [key: string]: any };
  public_product_name?: string;
  tier_name?: string;
  org_id?: number;
  request_dyn_dns: boolean;
  active_dyn_dns: boolean;
  dyn_dns_domain: string;
  dyn_dns_username: string;
  dyn_dns_password: string;
}
export interface AlertconfigCount {
  count: number;
  site_id: number;
}
export interface Token {
  exp: number;
  fresh: boolean;
  iat: number;
  identity: Identity;
  jti: string;
  nbf: number;
  type: string;
  user_claims: UserClaims;
}
export interface Tree {
  orgs: Org[];
}
export interface User {
  user_id: number;
  org_id: number;
  username: string;
  email: string;
  contact_number: string;
  orgname: string;
  job_description: string | null;
  first_name: string;
  last_name: string;
  enabled: boolean;
  web_roles: string[];
  end_point_id: number;
  type: string;
  totp_enabled?: boolean;
  entry_orgs?: any;
  alert_web_roles: string[];
}
export interface UserClaims {
  first_name: string;
  last_name: string;
  user_id: number;
  entry_orgs: OrgSelect[];
  roles: string[];
}
export interface UpdateResult {
  success: boolean;
  msg: string;
}
export interface UpdateSensorResult {
  success: boolean;
  msg: string;
}
export interface TalkbackRelay {
  success: boolean;
  msg: TalkbackRelayElements[];
}

export interface TalkbackRelayElements {
  id: number;
  org_id: number;
  name: string;
}
export interface UsersForOrg {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  users: User[];
}

export interface addLogosOrg {
  org_id: number;
  logo: string;
  success: any;
}

export interface fetchLogosOrg {
  org_id: number;
  image_type: any;
  success: any;
}

export interface UsersForEndPoint {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  users: User[];
}
export interface OwnUser {
  success: boolean;
  user: User;
}
export interface UserEndPointRoles {
  key: Guid;
  user_id: number;
  // end_point_roles: { [key: string]: any };
  web_roles: { [key: string]: any };
}
export type BillingExportMode = "single_csv_file" | "per_billing_org";
export interface UserWebRoles {
  user_id: number;
  first_name: string;
  last_name: string;
  supervisor: boolean;
  agent: boolean;
  username: string;
}
export interface UserRevisedWebRoles {
  user_id: number;
  first_name: string;
  last_name: string;
  username: string;
  web_alerts: boolean;
  web_alert_escalations: boolean;
  web_mute_controls: boolean;
  web_alert_camera_filter: boolean;
  web_alert_history: boolean;
  web_bulk_actions: boolean;
}

// Ajax interfaces
export interface AjaxRequest {
  ajax_hub: string;
  site_id: number | string;
  site_name: string;
}

export interface AjaxFirmware {
  version: number | string;
  newVersionAvailable: number | string;
  latestAvailableVersion: number | string;
  autoupdateEnabled: boolean | string;
}

export interface AjaxBattery {
  chargeLevelPercentage: number | string;
  state: string;
}

export interface AjaxHubStatus {
  subtype: string | null;
  name: string;
  id: number | string;
  state: string;
  tampered: string;
  externally_powered: string;
  firmware: AjaxFirmware;
  battery: AjaxBattery;
  malfunctions: any[] | string;
}

export interface AjaxDeviceStatus {
  name: string;
  sensor_id_str: string;
  arm_state: string;
  battery: number | string;
  malfunctions: any[] | string;
  online: boolean | string;
  tampered: string;
  message: string;
}

export interface FetchAjaxHubStatus {
  success: boolean;
  hub_status: AjaxHubStatus;
  device_statuses: AjaxDeviceStatus[];
}

export interface AjaxRequests {
  success: boolean;
  monitoring_requests: AjaxRequest[];
  user_request_code: number;
  user_requests_count: number;
  installer_request_code: number;
  installer_requests_count: number;
}

export class FunctionService {
  private authService: AuthService | undefined;
  private static ManageRestApiRoot = "/api/v1.3";
  private static StreamingUrl = "/stream/video";
  public locales: Locale[] = [];
  public endpointdefs: AlertEndPointDef[] = [];

  public setUrl(url: string) {
    if (url.includes("manage.staging.deepalert.ai") || url.includes("local")) {
      FunctionService.ManageRestApiRoot =
        "https://manage.staging.deepalert.ai/api/v1.3";
    } else if (url.includes("deepalert.ai")) {
      var slicedUrl = url.substring(0, url.indexOf("deepalert.ai"));
      FunctionService.ManageRestApiRoot = slicedUrl + "deepalert.ai/api/v1.3";
    } else if (url.includes("deepalert.io")) {
      var slicedUrl = url.substring(0, url.indexOf("deepalert.io"));
      FunctionService.ManageRestApiRoot = slicedUrl + "deepalert.io/api/v1.3";
    }
  }

  public setStreamingUrl(url: string) {
    if (url.includes("staging") || url.includes("local")) {
      FunctionService.StreamingUrl =
        "https://stream.staging.deepalert.ai/stream/video";
    } else if (url.includes("deepalert.ai")) {
      FunctionService.StreamingUrl = "https://stream.deepalert.ai/stream/video";
    } else if (url.includes("deepalert.io")) {
      FunctionService.StreamingUrl = "https://stream.deepalert.io/stream/video";
    }
  }

  public getStreamingUrl() {
    return FunctionService.StreamingUrl;
  }
  //##################
  //# AUTH FUNCTIONS #
  //##################

  async authenticateUser(
    username: string,
    password: string,
    mfaCode?: string
  ): Promise<AuthResult | any> {
    try {
      const user = !!mfaCode
        ? {
            username: username,
            password: password,
            totp: mfaCode,
          }
        : {
            username: username,
            password: password,
          };
      const res = fetch(FunctionService.ManageRestApiRoot + "/login", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const result = await (await res).json();
      result.code = (await res).status;
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async freshAuthenticateUser(
    username: string,
    password: string
  ): Promise<AuthResult | any> {
    try {
      const user = {
        username: username,
        password: password,
      };
      const res = fetch(FunctionService.ManageRestApiRoot + "/fresh_login", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const result = await (await res).json();
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }
  // Fetch Users Function (All Users and For Specific Org Tree)
  async getAllUsersForOrgFiltered(
    access_token: string,
    top_org_id: number,
    top_org_only: boolean
  ): Promise<User[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: top_org_only ? false : true,
        paginate: false,
        verbose: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async authenticateUserOrg(
    org_id: number,
    access_token: string
  ): Promise<AuthResult | any> {
    try {
      const org = {
        entry_org_id: org_id,
      };
      const res = fetch(FunctionService.ManageRestApiRoot + "/org_login", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(org),
      });
      const result = await (await res).json();
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async refreshAccessToken(refresh_token: string): Promise<AuthResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/refresh_access_token",
        {
          method: "post",
          headers: {
            Authorization: "Bearer " + refresh_token,
          },
        }
      );
      const result = await (await res).json();
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async removeAccessToken(access_token: string): Promise<AuthResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/logout_access_token",
        {
          method: "delete",
          headers: {
            Authorization: "Bearer " + access_token,
          },
        }
      );
      const result = await (await res).json();
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async removeRefreshToken(refresh_token: string): Promise<AuthResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/logout_refresh_token",
        {
          method: "delete",
          headers: {
            Authorization: "Bearer " + refresh_token,
          },
        }
      );
      const result = await (await res).json();
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  //#################
  //# GET FUNCTIONS #
  //#################
  async getActivityForOrg(
    access_token: string,
    top_org_id: number,
    start_time_filter: number,
    bin_secs: number,
    end_time_filter?: number | undefined
  ): Promise<any> {
    try {
      const request = !end_time_filter
        ? JSON.stringify({
            top_org_id: top_org_id,
            start_time_filter: start_time_filter,
            bin_secs: bin_secs,
          })
        : JSON.stringify({
            top_org_id: top_org_id,
            start_time_filter: start_time_filter,
            end_time_filter: end_time_filter,
            bin_secs: bin_secs,
          });

      const res = fetch(FunctionService.ManageRestApiRoot + "/activity", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;

      if (result.success === false) {
        return {
          success: true,
          msg: { inference_counts: [], alert_counts: [] },
        };
      }

      return result;
    } catch (err) {
      return err;
    }
  }

  async fetchAlertActivityStats(
    access_token: string,
    top_org_id: number | null = null,
    start_time_filter: number,
    bin_secs: number,
    end_time_filter?: number | undefined,
    site_ids: string | null = null,
    sensor_ids: string | null = null,
    signal?: AbortSignal | null | undefined
  ) {
    try {
      const request = !end_time_filter
        ? JSON.stringify({
            top_org_id: top_org_id,
            site_ids: site_ids,
            sensor_ids: sensor_ids,
            start_time_filter: start_time_filter,
            bin_secs: bin_secs,
            analysis_type: "alerts",
          })
        : JSON.stringify({
            top_org_id: top_org_id,
            site_ids: site_ids,
            sensor_ids: sensor_ids,
            start_time_filter: start_time_filter,
            end_time_filter: end_time_filter,
            bin_secs: bin_secs,
            analysis_type: "alerts",
          });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/analytics_activity",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
          signal: signal,
        }
      );

      const result = (await (await res).json()) as any;

      if (result.success === false) {
        return {
          success: true,
          msg: { inference_counts: [], alert_counts: [] },
        };
      }

      result.msg["inference_counts"] = [];

      return result;
    } catch (err) {
      return err;
    }
  }

  async fetchAInferenceActivityStats(
    access_token: string,
    top_org_id: number | null = null,
    start_time_filter: number,
    bin_secs: number,
    end_time_filter?: number | undefined,
    site_ids: string | null = null,
    sensor_ids: string | null = null,
    signal?: AbortSignal | null | undefined
  ) {
    try {
      const request = !end_time_filter
        ? JSON.stringify({
            top_org_id: top_org_id,
            site_ids: site_ids,
            sensor_ids: sensor_ids,
            start_time_filter: start_time_filter,
            bin_secs: bin_secs,
            analysis_type: "inferences",
          })
        : JSON.stringify({
            top_org_id: top_org_id,
            site_ids: site_ids,
            sensor_ids: sensor_ids,
            start_time_filter: start_time_filter,
            end_time_filter: end_time_filter,
            bin_secs: bin_secs,
            analysis_type: "inferences",
          });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/analytics_activity",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
          signal: signal,
        }
      );

      const result = (await (await res).json()) as any;

      if (result.success === false) {
        return {
          success: true,
          msg: { inference_counts: [], alert_counts: [] },
        };
      }

      result.msg["alert_counts"] = [];
      return result;
    } catch (err) {
      return err;
    }
  }
  async getHealthTimeLineForOrg(
    access_token: string,
    top_org_id: number,
    start_time_filter: number,
    bin_secs: number,
    signal?: AbortSignal | null | undefined
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/site_health_timeline",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
          signal: signal,
        }
      );
      const result = (await (await res).json()) as any;

      if (result.success === false) {
        return { success: true, msg: [] };
      }

      return result;
    } catch (err) {
      return err;
    }
  }
  async checkVersion(): Promise<CheckVersionResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/min_version_check",
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result as CheckVersionResult;
    } catch (err) {
      return err;
    }
  }

  async getActivityForSites(
    access_token: string,
    site_ids: number[],
    start_time_filter: number,
    bin_secs: number
  ): Promise<any> {
    try {
      let site_id_filters = "[";
      site_ids.forEach((site_id) => {
        site_id_filters = site_id_filters + site_id + ",";
      });
      site_id_filters =
        site_id_filters.substr(0, site_id_filters.length - 1) + "]";
      const request = JSON.stringify({
        site_ids: site_id_filters,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/activity", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });

      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      return err;
    }
  }
  async getHealthTimeLineForSites(
    access_token: string,
    site_ids: number[],
    start_time_filter: number,
    bin_secs: number,
    signal?: AbortSignal | null | undefined
  ): Promise<any> {
    try {
      let site_id_filters = "[";
      site_ids.forEach((site_id) => {
        site_id_filters = site_id_filters + site_id + ",";
      });
      site_id_filters =
        site_id_filters.substr(0, site_id_filters.length - 1) + "]";
      const request = JSON.stringify({
        site_ids: site_id_filters,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/site_health_timeline",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
          signal: signal,
        }
      );
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      return err;
    }
  }
  async getActivityForSensors(
    access_token: string,
    sensor_ids: number[],
    start_time_filter: number,
    bin_secs: number
  ): Promise<any> {
    try {
      let sensor_id_filters = "[";
      sensor_ids.forEach((sensor_id) => {
        sensor_id_filters = sensor_id_filters + sensor_id + ",";
      });
      sensor_id_filters =
        sensor_id_filters.substr(0, sensor_id_filters.length - 1) + "]";
      const request = JSON.stringify({
        sensor_ids: sensor_id_filters,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/activity", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      return err;
    }
  }
  async getHealthTimeLineForSensor(
    access_token: string,
    site_id: number,
    sensor_id_str: string,
    start_time_filter: number,
    bin_secs: number,
    signal?: AbortSignal | null | undefined
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/sensor_health_timeline",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
          signal: signal,
        }
      );
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      return err;
    }
  }
  async getAlertChannelsForSite(
    access_token: string,
    site_id: number
  ): Promise<AlertChannel[] | any> {
    if (site_id != undefined) {
      try {
        const request = JSON.stringify({
          site_id: site_id,
          verbose: true,
          per_page: 100,
          top_org_only: false,
          paginate: false,
        });

        const res = fetch(
          FunctionService.ManageRestApiRoot + "/list_alert_channels",
          {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
            },
            body: request,
          }
        );
        const result = (await (await res).json()) as AlertChannelsForOrg;
        let ret = result.alert_channels;
        return ret;
      } catch (err) {
        return err;
      }
    } else {
      return [];
    }
  }
  async getAlertChannelsForUser(
    access_token: string,
    top_org_id: number,
    top_org_only: boolean
  ): Promise<{ [key: string]: any }[] | any> {
    try {
      let request = undefined;
      if (top_org_id !== 0) {
        request = JSON.stringify({
          top_org_id: top_org_id,
          top_org_only: top_org_only,
        });
      } else {
        request = JSON.stringify({
          top_org_only: top_org_only,
        });
      }

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_sites_and_arm_statuses",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result.sites;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getAlertEndPointDefs(
    access_token: string
  ): Promise<AlertEndPointDef[] | any> {
    try {
      const request = JSON.stringify({
        verbose: true,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_end_point_defs",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetAlertEndPointDefs;
      let ret = result.alert_channel_end_point_defs;

      return ret;
    } catch (err) {
      return err;
    }
  }
  async getAlertEndPoints(
    access_token: string,
    alert_channel_id: any
  ): Promise<AlertEndPoint[] | any> {
    try {
      const request = JSON.stringify({
        alert_channel_ids: `${alert_channel_id}`,
        verbose: true,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_end_points",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetAlertEndPoints;
      let ret = result.alert_endpoints;
      let tel_def = result.telegram_definition;
      return { ret, tel_def };
    } catch (err) {
      return err;
    }
  }
  async getSingleAlertEndPoint(
    access_token: string,
    alert_channel_id: number,
    alert_channel_end_point_id: number
  ): Promise<GetAlertEndPoints[] | any> {
    try {
      const request = JSON.stringify({
        alert_channel_ids: `${alert_channel_id}`,
        alert_channel_end_point_id: alert_channel_end_point_id,
        verbose: true,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_end_points",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetAlertEndPoints;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getHikAlertEndPoints(
    access_token: string,
    alert_channel_id: number,
    end_point_type: string
  ): Promise<GetAlertEndPoints[] | any> {
    try {
      const request = JSON.stringify({
        alert_channel_ids: `${alert_channel_id}`,
        end_point_type: end_point_type,
        verbose: true,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_end_points",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetAlertEndPoints;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getTierInfo(
    access_token: string,
    org_id: number,
    new_site: boolean
  ): Promise<OrgBillingRate[] | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        new_site: new_site,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_org_billing_rates",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as BillingRatesForOrg;
      let ret = result.org_billing_rates;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getBillingRatesForOrg(
    access_token: string,
    org_id: number,
    new_site: boolean
  ): Promise<OrgBillingRate[] | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        new_site: new_site,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_org_billing_rates",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as BillingRatesForOrg;
      let ret = result.org_billing_rates;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getBillingRates(access_token: string): Promise<BillingRate[] | any> {
    try {
      const request = JSON.stringify({
        verbose: true,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_billing_rates",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result.billing_rates;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async updateNotes(
    access_token: string,
    org_id: number,
    predefined_notes: NotesProps
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        org_id,
        predefined_notes,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/notes?org_id=" + org_id,
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result.success;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getNotes(access_token: string, org_id: number): Promise<any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/notes?org_id=" + org_id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getClassifierForSite(
    access_token: string,
    site_id: number
  ): Promise<Classifier | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        verbose: true,
        paginate: false,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_classifier",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetClassifier;
      let ret = result.classifier;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getSiteDataOrigin(
    access_token: string,
    site_id: number
  ): Promise<DataOriginDefaults | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        verbose: true,
        paginate: false,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/site_data_origin",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as DataOriginDefaults;
      let ret = result.data_origin;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getSensorDefinedRules(
    access_token: string,
    site_id: number
  ): Promise<DataOriginDefaults | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + `/sensor_defined_rule_defs`,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result.rule_defs;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getConfigForAlertChannel(
    access_token: string,
    alert_channel_id: number
  ): Promise<AlertConfig[] | any> {
    try {
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
        verbose: true,
        paginate: false,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_alert_config",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetAlertConfig;
      let ret = result.alert_configs;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getHealthForOrg(
    access_token: string,
    org_id: number
  ): Promise<SiteHealth[] | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        paginate: false,
        include_sensors: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/site_health", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as HealthForOrg;
      let ret = result.msg.sites;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getHealthForSites(
    access_token: string,
    site_id: number,
    org_id: number
  ): Promise<SiteHealth[] | any> {
    try {
      // let site_id_filters = "[";
      // site_ids.forEach((site_id) => {
      //   site_id_filters = site_id_filters + site_id + ",";
      // });
      // site_id_filters =
      //   site_id_filters.substr(0, site_id_filters.length - 1) + "]";

      const request = JSON.stringify({
        site_id: site_id,
        // org_id,
        paginate: false,
        include_sensors: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/site_health", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as HealthForOrg;
      let ret = result.msg.sites;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getFrontelSites(
    access_token: string,
    org_id: number
  ): Promise<SiteHealth[] | any> {
    try {
      const request = JSON.stringify({
        org_id,
        paginate: false,
        top_org_only: false,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_frontel_site_status",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result.sites;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getHealthDetailForSite(
    access_token: string,
    site_id: number,
    start_time_filter?: number,
    end_time_filter?: number
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        start_time_filter: start_time_filter ? start_time_filter : null,
        end_time_filter: end_time_filter ? end_time_filter : null,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/site_health_detail",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as any;
      let ret = {};
      if (result.success) {
        ret = result.msg;
      }
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getHubs(access_token: string, hub_ids: number[]): Promise<Hub[] | any> {
    try {
      let hub_id_filters = "[";
      hub_ids.forEach((hub_id) => {
        hub_id_filters = hub_id_filters + hub_id + ",";
      });
      hub_id_filters =
        hub_id_filters.substr(0, hub_id_filters.length - 1) + "]";
      const request = JSON.stringify({
        hub_ids: hub_id_filters,
        verbose: true,
        top_org_only: false,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_hubs", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetHubs;
      let ret = result.hubs;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getHub(access_token: string, hub_id: number): Promise<Hub | any> {
    try {
      const request = JSON.stringify({
        hub_id: hub_id,
        verbose: true,
        top_org_only: false,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_hubs", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetHubs;
      let ret = result.hubs[0];
      ret.type = "hub";
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getAllHubs(
    access_token: string,
    top_org_id: number
  ): Promise<GetHubs | any> {
    try {
      const request = JSON.stringify({
        verbose: true,
        enabled: true,
        top_org_only: false,
        paginate: false,
        top_org_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_hubs", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetHubs;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getHubAlive(
    access_token: string,
    hub_id: number
  ): Promise<AliveResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_hub?hub_id=" + hub_id,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as AliveResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getHubCameras(
    access_token: string,
    hub_id_str: string | undefined
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        hub_id_str: hub_id_str,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/camera_scan", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getVideoInfo(
    access_token: string,
    hub_id_str: string,
    connection_str: string
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        hub_id_str: hub_id_str,
        connection_str: connection_str,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/video_info", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getAdvancedTests(
    access_token: string,
    hub_id_str: string,
    ip_address: string,
    port: number,
    connection_str: string
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        hub_id_str: hub_id_str,
        ip_address: ip_address,
        port: port,
        connection_str: connection_str,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/advanced_testing",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as any;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getHubStatus(
    access_token: string,
    hub_id: number
  ): Promise<StatusResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/status?hub_id=" + hub_id,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as StatusResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getHubsForSite(
    access_token: string,
    site_id: number
  ): Promise<Hub[] | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        top_org_only: false,
        enabled: true,
        verbose: true,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_hubs", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as HubsList;
      let ret = result.hubs;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getHubsSharedForOrg(
    access_token: string,
    top_org_id: number
  ): Promise<Hub[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        top_org_only: false,
        shared_hubs: true,
        enabled: true,
        paginate: false,
        verbose: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_hubs", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as HubsList;
      let ret = result.hubs;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async fetchStreamingBaseUrl(
    access_token: string,
    sensor_id_str: string
  ): Promise<StreamingUrlResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot +
          `/streaming_endpoint?sensor_id_str=${sensor_id_str}`,

        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            // "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as StreamingUrlResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async getUserLogs(
    access_token: string,
    groupid: string,
    selectedActions: string | null,
    siteid: string | null,
    newStartTime: number | null,
    newEndTime: number | null,
    sensorId: string | null | undefined,
    selectedUserId: string | null | undefined
  ): Promise<any[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: groupid === "-1" ? undefined : +groupid,
        action_types: selectedActions === "" ? undefined : selectedActions,
        site_ids: siteid === "-1" || !siteid ? undefined : siteid,
        created_datetime_start: newStartTime,
        created_datetime_end: newEndTime,
        sensor_ids: sensorId === "-1" || !sensorId ? undefined : sensorId,
        by_user_ids: selectedUserId === "0" ? undefined : selectedUserId,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/user_activity_log",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result.results;
      return ret;
    } catch (err) {
      return [];
    }
  }

  async getActionTypes(
    access_token: string,
    groupid: string
  ): Promise<any[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: groupid === "-1" ? undefined : +groupid,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/user_activity_types",
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          // body: request,
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result;
      return ret;
    } catch (err) {
      console.log("Action Types results failed: ", err);
      return [];
    }
  }

  async getUserWebOrgs(
    access_token: string,
    userId: number
  ): Promise<any[] | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + `/user_web_orgs?user_id=${userId}`,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            // "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as any;
      let ret = result;
      return ret;
    } catch (err) {
      console.log("web orgs results failed: ", err);
      return [];
    }
  }
  async getHubsSharedForOrgSearch(
    access_token: string,
    top_org_id: number,
    org_id: number,
    hub_id_str: string
  ): Promise<Hub[] | any> {
    try {
      const request = JSON.stringify({
        top_org_only: false,
        shared_hubs: true,
        enabled: true,
        paginate: false,
        verbose: true,
        hub_id_str,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_hubs", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as HubsList;
      let ret = result.hubs;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getHubsTokenDetail(
    access_token: string,
    hub_id_str: string
  ): Promise<HubTokenDetail | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot +
          "/hub_token_detail?hub_id_str=" +
          hub_id_str,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as HubTokenDetail;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getHubMessageToken(
    access_token: string,
    hub_id_str: string
  ): Promise<GetMessageToken | any> {
    try {
      const request = JSON.stringify({
        hub_id_str: hub_id_str,
        enable: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/message_token", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetMessageToken;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getLocale(
    access_token: string,
    locale: string
  ): Promise<Locale[] | any> {
    try {
      const request = JSON.stringify({
        locale: locale,
        verbose: true,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_locale", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetLocale;
      let ret = result.locales;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getLocales(access_token: string): Promise<Locale[] | any> {
    try {
      const request = JSON.stringify({
        verbose: false,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_locale", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetLocaleList;
      let ret = result.locales;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getNewHubActivationToken(
    access_token: string,
    hub_id_str: string,
    reissue: boolean
  ): Promise<HubActivationToken | any> {
    try {
      const request = JSON.stringify({
        hub_id_str: hub_id_str,
        reissue: reissue,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/hub_token", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as HubActivationToken;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getNewHubIDString(access_token: string): Promise<string | any> {
    try {
      const res = fetch(FunctionService.ManageRestApiRoot + "/hub_token", {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
      });
      const result = (await (await res).json()) as NewHubId;
      let ret = result.new_hub_id_str;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getOrgOneLevel(
    access_token: string,
    org_id: number
  ): Promise<Org | undefined | any> {
    if (org_id != undefined) {
      try {
        const res = fetch(
          FunctionService.ManageRestApiRoot + "/org_tree?top_org_id=" + org_id,
          {
            method: "get",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const result = (await (await res).json()) as GetOrgOneLevel;
        let ret: Org | undefined = undefined;
        if (result.success) {
          ret = result.org;
        }
        return ret;
      } catch (err) {
        return err;
      }
    } else {
      var err = new Error();
      return undefined;
    }
  }

  async getOrgBreadcrumb(
    access_token: string,
    org_id: number
  ): Promise<Org | any> {
    if (org_id === undefined) {
      var err = new Error();
    }
    try {
      const request = JSON.stringify({
        top_org_id: org_id,
        depth: 0,
        incl_sites: false,
        incl_hubs: false,
        incl_alert_channels: false,
        incl_users: false,
        incl_org_tree: true,
        incl_org_level: false,
        incl_enabled: true,
        enabled_orgs: null,
        enabled_sites: null,
        enabled_hubs: null,
        enabled_alert_channels: null,
        enabled_users: null,
        paginate: false,
        incl_num_site_reports: false,
        incl_site_sensor_meta: false,
      });
      const res = fetch(FunctionService.ManageRestApiRoot + "/org_tree", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as OrgTree;
      let ret = result.tree.orgs[org_id];
      ret.org_id = org_id;
      ret.type = "org";
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getOrgsList(access_token: string): Promise<OrgsList | any> {
    try {
      const request = JSON.stringify({});

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_entry_orgs",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as OrgsList;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getListOrgs(access_token: string): Promise<OrgsList | any> {
    try {
      const request = JSON.stringify({
        enabled: true,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_orgs", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as OrgsList;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getListOrgsWithTreeNames(
    access_token: string
  ): Promise<OrgsList | any> {
    try {
      const request = JSON.stringify({
        enabled: true,
        paginate: false,
        verbose: false,
        incl_tree_names: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_orgs", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as OrgsList;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getNetworkConfigForHub(
    access_token: string,
    hub_id: number
  ): Promise<any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/network_config?hub_id=" + hub_id,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as GetNetworkConfig;
      let ret = result.config;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getOrgTreeTotals(
    access_token: string,
    top_org_id: number
  ): Promise<OrgTreeCount | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/org_tree_totals",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as OrgTreeCount;
      return result;
    } catch (err) {
      return err;
    }
  }

  async getReportGroupsFromCurrentLevelUp(
    access_token: string,
    org_id: number
  ): Promise<ReportGroup[] | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_org_eligible_report_groups",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetReportGroups;
      let ret = result.report_groups;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getReportGroups(
    access_token: string,
    top_org_id: number
  ): Promise<ReportGroup[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        top_org_only: false,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_report_groups",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetReportGroups;
      let ret = result.report_groups;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getReportGroupsForTopOrg(
    access_token: string,
    top_org_id: number
  ): Promise<ReportGroup[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        top_org_only: true,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_report_groups",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetReportGroups;
      let ret = result.report_groups;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getRolesForEndPointType(
    access_token: string,
    end_point_type: string
  ): Promise<Role[] | any> {
    try {
      const request = JSON.stringify({
        end_point_type: end_point_type,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_roles", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as RolesForEndpoint;
      let ret = result.roles;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getAllRoles(access_token: string): Promise<Role[] | any> {
    try {
      const request = JSON.stringify({
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_roles", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as RolesForEndpoint;
      let ret = result.roles;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getAllRegisteredUsers(
    access_token: string
  ): Promise<RegisteredUsersResult | any> {
    try {
      const request = JSON.stringify({});

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/registration_details",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as RegisteredUsersResult;

      return result;
    } catch (err) {
      return err;
    }
  }
  async getProcessedRegisteredUsers(
    access_token: string,
    processed: boolean
  ): Promise<RegisteredUsersResult | any> {
    try {
      const request = JSON.stringify({
        processed,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/registration_details",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as RegisteredUsersResult;

      return result;
    } catch (err) {
      return err;
    }
  }
  async addRegisteredUser(
    access_token: string,
    entry_org_id: number,
    roles: string,
    reg_id: number,
    processed: boolean
  ): Promise<RegisteredUsersResult | any> {
    try {
      const request = JSON.stringify({
        entry_org_id,
        roles,
        reg_id,
        processed,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/registration_details",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as RegisteredUsersResult;

      return result;
    } catch (err) {
      return err;
    }
  }
  async UpdateProcessedUser(
    access_token: string,
    processed: boolean,
    reg_id: number
  ): Promise<RegisteredUsersResult | any> {
    try {
      const request = JSON.stringify({
        processed,
        reg_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/register", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as RegisteredUsersResult;

      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteRegisteredUser(
    access_token: string,
    reg_id: number
  ): Promise<RegisteredUsersResult | any> {
    try {
      const request = JSON.stringify({
        reg_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/registration_details",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as RegisteredUsersResult;

      return result;
    } catch (err) {
      return err;
    }
  }
  async getEnabledSensorsForSite(
    access_token: string,
    site_id: number,
    image: string
  ): Promise<Sensor[] | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        enabled: true,
        verbose: true,
        paginate: false,
        image: image,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_sensors", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });

      const result = (await (await res).json()) as SensorsForSite;

      let ret = result.sensors;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getSensorsForSite(
    access_token: string,
    site_id: number,
    image: string
  ): Promise<Sensor[] | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        verbose: true,
        paginate: false,
        image: image,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_sensors", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as SensorsForSite;
      let ret = result.sensors;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getSensorsForHub(
    access_token: string,
    hub_id: number,
    image: string
  ): Promise<Sensor[] | any> {
    try {
      const request = JSON.stringify({
        hub_id: hub_id,
        verbose: true,
        paginate: false,
        image: image,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_sensors", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as SensorsForSite;
      let ret = result.sensors;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getAllSensors(access_token: string): Promise<GetSensors | any> {
    try {
      const request = JSON.stringify({
        verbose: true,
        enabled: true,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_sensors", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetSensors;

      return result;
    } catch (err) {
      return err;
    }
  }
  async getSensor(
    access_token: string,
    sensor_id: number,
    image: string | null = null
  ): Promise<Sensor | any> {
    try {
      const request = JSON.stringify({
        sensor_id: sensor_id,
        verbose: true,
        enabled: true,
        paginate: false,
        image,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_sensors", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;
      let ret = undefined;
      if (result.success && result.sensors && result.sensors.length > 0) {
        ret = result.sensors[0];
      }
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getSite(
    access_token: string,
    site_id: number
  ): Promise<Site | undefined | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        verbose: true,
        top_org_only: false,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_sites", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      let ret = undefined;
      const response = await res;
      if (response.status === 200 || response.status === 201) {
        const result = (await response.json()) as GetSites;
        if (result.success) {
          ret = result.sites[0];
          ret.type = "site";
        }
      }
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getAjaxHub(
    access_token: string,
    site_id: number
  ): Promise<String | undefined | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/get_ajax_details",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      let ret = undefined;
      const response = await res;
      if (response.status === 200 || response.status === 201) {
        const result = await response.json();
        if (result.success) {
          ret = result.hub_id;
        }
      }
      return ret;
    } catch (err) {
      return err;
    }
  }

  async handleAjaxArming(
    access_token: string,
    site_id: number,
    ajax_hub_id: string,
    status: string
  ): Promise<String | undefined | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        ajax_hub_id: ajax_hub_id,
        status: status,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_ajax_arm",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      let ret = "No message returned, please contact DeepAlert support";
      const response = await res;
      const result = await response.json();
      if (result.msg) {
        ret = result.msg;
      }
      return ret;
    } catch (err) {
      return err;
    }
  }

  async listGlobalSites(
    access_token: string,
    top_org_id: number
  ): Promise<Site | undefined | any> {
    try {
      const request = JSON.stringify({
        enabled: true,
        top_org_id,
        paginate: false,
        top_org_only: false,
        verbose: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_sites", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = await (await res).json();
      return result;
    } catch (err) {
      return err;
    }
  }
  async getSiteReports(
    access_token: string,
    site_id: number
  ): Promise<SiteReport[] | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_site_reports",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as GetSiteReports;
      let ret = result.site_reporting_configs;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getSnapshot(
    access_token: string,
    sensor_id: number
  ): Promise<GetSnapShot | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot +
          "/hub_snapshot?sensor_id=" +
          sensor_id,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as GetSnapShot;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getSnapshotFromServer(
    access_token: string,
    sensor_id: number
  ): Promise<SnapshotFromServer | any> {
    try {
      const request = JSON.stringify({
        sensor_id: sensor_id,
        max_count: 1,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/server_snapshot",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as SnapshotFromServer;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getUser(
    access_token: string,
    user_id: number
  ): Promise<User | undefined | any> {
    try {
      const request = JSON.stringify({
        user_id: user_id,
        verbose: true,
        order_by: "username",
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForEndPoint;
      if (result.success && result.users.length >= 0) {
        return result.users[0];
      } else {
        return undefined;
      }
    } catch (err) {
      return err;
    }
  }
  async getOwnUser(
    access_token: string,
    user_id: number
  ): Promise<User | undefined | any> {
    try {
      const request = JSON.stringify({
        user_id: user_id,
        verbose: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_self", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as OwnUser;
      if (result.success && result.user) {
        return result.user;
      } else {
        return undefined;
      }
    } catch (err) {
      return err;
    }
  }
  async getUserEntryPoints(
    access_token: string,
    user_id: number
  ): Promise<EntryPoint[] | any> {
    try {
      const request = JSON.stringify({
        user_id: user_id,
        paginate: false,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_entry_points",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as EntryPointsForUser;
      let ret = result.user_entry_points;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async enableOTP(
    access_token: string,
    totp_code: string
  ): Promise<EntryPoint[] | any> {
    try {
      const request = JSON.stringify({
        totp_code: totp_code,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/validate_totp", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getOtpUri(access_token: string): Promise<EntryPoint[] | any> {
    try {
      const res = fetch(FunctionService.ManageRestApiRoot + "/configure_totp", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
      });
      const result = (await (await res).json()) as any;
      let ret = result.uri;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getUsersForEndPoint(
    access_token: string,
    alert_channel_end_point_id: number
  ): Promise<User[] | any> {
    try {
      const request = JSON.stringify({
        alert_channel_end_point_id: alert_channel_end_point_id,
        paginate: false,
      });

      // const res = fetch(FunctionService.ManageRestApiRoot + "/user_end_point", {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/web_end_point_users",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as UsersForEndPoint;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getUsersForOrg(
    access_token: string,
    org_id: number
  ): Promise<User[] | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        order_by: "username",
        top_org_only: false,
        verbose: true,
        paginate: false,
        // enabled: null
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getAllVisibleUsers(
    access_token: string,
    top_org_id: number,
    enabled: boolean | null = null
  ): Promise<User[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: false,
        paginate: false,
        verbose: true,
        enabled: !enabled ? !enabled : null,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async fetchUserRoles(
    access_token: string,
    top_org_id: number,
    user_id: number
  ): Promise<WebChannelResults | any> {
    try {
      const request = JSON.stringify({
        top_org_id,
        user_id,
      });

      const res = await fetch(
        FunctionService.ManageRestApiRoot + "/user_web_channels",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );

      const result = (await (await res).json()) as WebChannelResults;
      return result;
    } catch (err) {}
  }
  async fetchUserWebRoleNames(
    access_token: string,
    user_id: number
  ): Promise<WebChannelResults | any> {
    try {
      const res = await fetch(
        FunctionService.ManageRestApiRoot +
          `/user_web_roles?user_id=${user_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      );

      const result = (await (await res).json()) as any;
      return result.web_roles;
    } catch (err) {}
  }

  async updateUserRoles(
    access_token: string,
    top_org_id: number,
    user_id: number,
    web_end_point_roles: string,
    alert_channel_ids: string
  ): Promise<UpdateWebChannelResults | any> {
    try {
      const request = JSON.stringify({
        top_org_id,
        user_id,
        web_end_point_roles,
        alert_channel_ids,
      });

      const res = await fetch(
        FunctionService.ManageRestApiRoot + "/user_web_channels",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );

      const result = (await (await res).json()) as UpdateWebChannelResults;
      return result;
    } catch (err) {}
  }
  // Fetch Users Function (All Users and For Specific Org Tree)
  async getAllUsersForOrgIncSubGroups(
    access_token: string,
    top_org_id: number,
    top_org_only: boolean
  ): Promise<User[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: top_org_only ? false : true,
        paginate: false,
        verbose: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getAllUsersForAllOrg(
    access_token: string,
    top_org_id: number
  ): Promise<User[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: false,
        paginate: false,
        verbose: true,
        enabled: true,
      });
      const res = fetch(FunctionService.ManageRestApiRoot + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async getAllUsersForOrg(
    access_token: string,
    top_org_id: number
  ): Promise<User[] | any> {
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: true,
        paginate: false,
        verbose: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  }
  async addLogo(
    access_token: string,
    org_id: number,
    logo: string
  ): Promise<any | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        logo: logo,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_org_logo",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addLogosOrg;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async deleteThumbnail(
    access_token: string,
    org_id: number,
    logo: string
  ): Promise<any | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        logo: logo,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_org_logo",
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addLogosOrg;
      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async fetchLogo(
    access_token: string,
    org_id: number,
    image_type: string
  ): Promise<any | any> {
    if (typeof org_id !== "number" || !Number.isInteger(org_id)) {
      throw new Error("org_id must be an integer.");
    }
    try {
      const request = JSON.stringify({
        org_id: org_id,
        image_type: image_type,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot +
          "/fetch_org_logo?org_id=" +
          org_id +
          "&image_type=" +
          image_type,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as fetchLogosOrg;

      let ret = result;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async orgDefaultRuleDefs(
    token: string,
    org_id: number | undefined
  ): Promise<any> {
    try {
      const request = {
        org_id, // Assuming you're using org_id instead of site_id
      };
      const url = `${FunctionService.ManageRestApiRoot}/org_default_rule_defs`;
      const res = await fetch(url, {
        method: "POST", // Change method to POST
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request), // Send request body with site_id
      });
      const result = await res.json();
      return result;
    } catch (err) {
      return err;
    }
  }

  async siteDefaultRuleDefs(
    token: string,
    site_id: number | undefined
  ): Promise<any> {
    try {
      const request = {
        site_id, // Assuming you're using org_id instead of site_id
      };
      const url = `${FunctionService.ManageRestApiRoot}/site_default_rule_defs`;
      const res = await fetch(url, {
        method: "POST", // Change method to POST
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request), // Send request body with site_id
      });
      const result = await res.json();
      return result;
    } catch (err) {
      return err;
    }
  }

  async fetchSiteDefaultRules(
    token: string,
    site_id: number | undefined
  ): Promise<any> {
    try {
      const request = {
        site_id, // Assuming you're using site_id instead of org_id
      };

      const url = `${FunctionService.ManageRestApiRoot}/fetch_nearest_default_rules`;

      const res = await fetch(url, {
        method: "POST", // Change method to POST
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request), // Send request body with org_id
      });

      const result = await res.json();
      return result;
    } catch (err) {
      return err;
    }
  }

  async fetchGroupDefaultRules(
    token: string,
    org_id: number | undefined
  ): Promise<any> {
    try {
      const request = {
        org_id, // Assuming you're using org_id instead of site_id
      };

      const url = `${FunctionService.ManageRestApiRoot}/fetch_nearest_default_rules`;

      const res = await fetch(url, {
        method: "POST", // Change method to POST
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request), // Send request body with org_id
      });

      const result = await res.json();
      return result;
    } catch (err) {
      return err;
    }
  }

  async listRulesForSite(
    token: string,
    site_id: number | undefined
  ): Promise<any> {
    try {
      const request = {
        site_id, // Assuming you're using org_id instead of site_id
      };

      const url = `${FunctionService.ManageRestApiRoot}/list_rules_for_site`;

      const res = await fetch(url, {
        method: "POST", // Change method to POST
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request), // Send request body with org_id
      });

      const result = await res.json();
      return result;
    } catch (err) {
      return err;
    }
  }

  async updateSiteDefaultRules(
    token: string,
    site_id: number | undefined,
    default_rules: any
  ): Promise<any> {
    let tempDefaultRules = {
      ...default_rules,
      rules: default_rules?.rules?.length ? default_rules?.rules : {},
    };
    try {
      const request = {
        site_id, // Assuming you're using site_id instead of org_id
        default_rules: tempDefaultRules,
      };
      const url = `${FunctionService.ManageRestApiRoot}/update_default_rules`;
      const res = await fetch(url, {
        method: "PUT", // Change method to POST
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request), // Send request body with site_id
      });

      const result = await res.json();
      return result;
    } catch (err) {
      return err;
    }
  }

  async updateGroupDefaultRules(
    token: string,
    org_id: number | undefined,
    default_rules: any
  ): Promise<any> {
    let tempDefaultRules = {
      ...default_rules,
      rules: default_rules?.rules?.length ? default_rules?.rules : {},
    };
    try {
      const request = {
        org_id, // Assuming you're using org_id instead of site_id
        default_rules: tempDefaultRules,
      };
      const url = `${FunctionService.ManageRestApiRoot}/update_default_rules`;
      const res = await fetch(url, {
        method: "PUT", // Change method to POST
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request), // Send request body with org_id
      });

      const result = await res.json();
      return result;
    } catch (err) {
      return err;
    }
  }

  //####################
  //# CREATE FUNCTIONS #
  //####################

  async createAlertChannel(
    access_token: string,
    locale: string,
    alert_channel_name: string,
    site_id: number,
    timezone: string,
    data_origin: string
  ): Promise<CreateAlertChannelResult | any> {
    try {
      var request;
      let default_confidence_threshold;

      let meta: any = { additional_inference_options: {} };
      meta.additional_inference_options["ignore_stationary_vehicles"] = true;

      if (data_origin.includes("motioncam")) {
        meta = undefined;
      }

      if (site_id != 0) {
        if (
          data_origin === "client_hub_motioncam_security" ||
          data_origin === "system_hub_motioncam_security"
        ) {
          default_confidence_threshold = 70;
        } else if (data_origin === "inference_api_client") {
          default_confidence_threshold = 35;
        } else {
          default_confidence_threshold = 35;
        }
        request = JSON.stringify({
          site_id: site_id,
          alert_channel_name: alert_channel_name,
          timezone_str: timezone,
          locale: locale,
          default_confidence_threshold: default_confidence_threshold,
          meta: meta,
        });
      } else {
        if (
          data_origin === "client_hub_motioncam_security" ||
          data_origin === "system_hub_motioncam_security"
        ) {
          default_confidence_threshold = 70;
        } else if (data_origin === "inference_api_client") {
          default_confidence_threshold = 35;
        } else {
          default_confidence_threshold = 35;
        }

        request = JSON.stringify({
          alert_channel_name: alert_channel_name,
          timezone_str: timezone,
          default_confidence_threshold: default_confidence_threshold,
          meta: meta,
        });
      }
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_alert_channel",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateAlertChannelResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createAlertConfig(
    access_token: string,
    alert_channel_id: number,
    class_labels: string,
    sensor_ids: string,
    valid_days_and_times: string,
    snapshot?: boolean
  ): Promise<CreateAlertConfigResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
        class_labels: class_labels,
        sensor_ids: sensor_ids,
        valid_days_and_times: snapshot ? undefined : valid_days_and_times,
        exact_times: snapshot
          ? valid_days_and_times.replace(/-24:00:00/g, "")
          : undefined,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_alert_config",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateAlertConfigResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async createAlertConfigNoSchedule(
    access_token: string,
    alert_channel_id: number,
    class_labels: string,
    sensor_ids: string
  ): Promise<CreateAlertConfigResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
        class_labels: class_labels,
        sensor_ids: sensor_ids,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_alert_config",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateAlertConfigResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async editBillingProfile(
    access_token: string,
    profile_id: number,
    name: string = "",
    description: string = ""
  ): Promise<AddBillingProfileResult | any> {
    try {
      const request =
        description != "" && name != ""
          ? JSON.stringify({
              profile_id,
              name,
              description,
            })
          : name != ""
          ? JSON.stringify({
              profile_id,
              name,
            })
          : JSON.stringify({
              profile_id,
              description,
            });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_profile",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as AddBillingProfileResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteBillingProfile(
    access_token: string,
    profile_id: number
  ): Promise<AddBillingProfileResult | any> {
    try {
      const request = JSON.stringify({
        profile_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_profile",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as AddBillingProfileResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async addBillingProfile(
    access_token: string,
    name: string,
    description: string = ""
  ): Promise<AddBillingProfileResult | any> {
    try {
      const request =
        description === ""
          ? JSON.stringify({
              name,
            })
          : JSON.stringify({
              name,
              description,
            });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_profile",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as AddBillingProfileResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async listBillingProfiles(
    access_token: string
  ): Promise<listBillingResult | any> {
    try {
      const request = JSON.stringify({});
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_billing_profiles",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as listBillingResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async addBillingRate(
    access_token: string,
    tier_id: number,
    product_name_id: number,
    data_origin_id: number,
    rate_inf: { [key: string]: any },
    product: string,
    classifier_id: number
  ): Promise<addBillingOrgResult | any> {
    let rate_info = JSON.stringify(rate_inf);
    try {
      let request = JSON.stringify({
        product,
        product_name_id,
        data_origin_id,
        rate_info,
        tier_id,
        classifier_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_rates",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (error) {
      return error;
    }
  }

  async editBillingRate(
    access_token: string,
    id: number,
    product: string,
    classifier_id: number,
    tier_id: number,
    product_name_id: number,
    data_origin_id: number
  ): Promise<addBillingOrgResult | any> {
    try {
      let requestBody = {};
      requestBody = { ...requestBody, id };
      if (classifier_id !== 0) {
        requestBody = { ...requestBody, classifier_id };
      }
      if (tier_id !== 0) {
        requestBody = { ...requestBody, tier_id };
      }
      if (product_name_id !== 0) {
        requestBody = { ...requestBody, product_name_id };
      }
      if (data_origin_id !== 0) {
        requestBody = { ...requestBody, data_origin_id };
      }
      if (product !== "") {
        requestBody = { ...requestBody, product };
      }

      let request = JSON.stringify(requestBody);
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_rates",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (error) {
      return error;
    }
  }

  async listBillingRates(
    access_token: string
  ): Promise<listBillingRates | any> {
    try {
      let include_enabled_site_counts = true;
      const request = JSON.stringify({ include_enabled_site_counts });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_billing_rates",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as listBillingRates;
      return result;
    } catch (err) {
      return err;
    }
  }
  async listBillingRateSupportItems(
    access_token: string
  ): Promise<listBillingRateSupportItems | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/billing_rate_support",
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as listBillingRateSupportItems;
      return result;
    } catch (err) {
      return err;
    }
  }
  async listClassLabels(
    access_token: string,
    name: string
  ): Promise<listClassLabels | any> {
    try {
      const request = JSON.stringify({ name });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_classifier",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as listClassLabels;
      return result;
    } catch (err) {
      return err;
    }
  }
  async listOrgBillingRates(
    access_token: string,
    org_id: number
  ): Promise<listOrgBillingRates | any> {
    try {
      const request = JSON.stringify({ org_id });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_org_billing_rates",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as listOrgBillingRates;
      return result;
    } catch (err) {
      return err;
    }
  }
  async listBillingRatesEndPoints(
    access_token: string,
    billing_rate_id: number
  ): Promise<listBillingRatesEndPoints | any> {
    try {
      const request = JSON.stringify({ billing_rate_id });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_billing_rate_end_points",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as listBillingRatesEndPoints;
      return result;
    } catch (err) {
      return err;
    }
  }
  async listBillingProductOrgs(
    access_token: string,
    billing_rate_id: number
  ): Promise<listBillingProductOrgs | any> {
    try {
      const request = JSON.stringify({ billing_rate_id });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_product_orgs",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as listBillingProductOrgs;
      return result;
    } catch (err) {
      return err;
    }
  }
  async addBillingOrg(
    access_token: string,
    profile_id: number,
    billing_org_id: number,
    billing_org_name: string,
    description: string = ""
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        profile_id,
        billing_org_id,
        billing_org_name,
        description,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_profile_org",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async updateBillingOrg(
    access_token: string,
    billing_profile_org_id: number,
    description: string,
    billing_org_name: string,
    enabled: boolean
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        billing_profile_org_id,
        description,
        billing_org_name,
        enabled,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_profile_org",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteBillingOrg(
    access_token: string,
    billing_profile_org_id: number
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        billing_profile_org_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_profile_org",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async listBillingProfileUsers(
    access_token: string,
    profile_id: number
  ): Promise<ListBillingProfileUsersResult | any> {
    try {
      const request = JSON.stringify({
        profile_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_billing_profile_users",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (
        await res
      ).json()) as ListBillingProfileUsersResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async addBillingProfileUser(
    access_token: string,
    profile_id: number,
    user_id: number
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        profile_id,
        user_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_profile_user",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteBillingProfileUser(
    access_token: string,
    billing_profile_user_id: number
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        billing_profile_user_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_profile_user",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async listBillingProfileOrgs(
    access_token: string,
    profile_id: number
  ): Promise<ListBillingProfileOrgsResult | any> {
    try {
      const request = JSON.stringify({
        profile_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_billing_profile_orgs",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as ListBillingProfileOrgsResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async listBillingRuns(
    access_token: string,
    profile_id: number
  ): Promise<ListBillingRunsResult | any> {
    try {
      const request = JSON.stringify({
        profile_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_billing_runs",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as ListBillingRunsResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async ListBillingRunTypes(
    access_token: string
  ): Promise<ListBillingRunTypesResult> {
    const res = fetch(
      FunctionService.ManageRestApiRoot + "/list_billing_run_types",
      {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
      }
    );
    const result = await (await res).json();
    result.code = (await res).status;
    return result as ListBillingRunTypesResult;
  }
  async addBillingRun(
    access_token: string,
    profile_id: number,
    run_type_id: number,
    name: string,
    run_date: string,
    description: string = ""
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        profile_id,
        run_type_id,
        name,
        run_date,
        description,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_run",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async editBillingRun(
    access_token: string,
    billing_run_id: number,
    name: string,
    description: string
  ): Promise<editBillingRunResult | any> {
    try {
      const request = JSON.stringify({
        billing_run_id,
        name,
        description,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_run",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as editBillingRunResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteBillingRun(
    access_token: string,
    billing_run_id: number
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        billing_run_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_billing_run",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async submitBillingCalculation(
    access_token: string,
    billing_run_id: number,
    billing_org_ids: string,
    incl_pro_rata: boolean,
    finalise: boolean
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        billing_run_id,
        billing_org_ids,
        incl_pro_rata,
        finalise,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/calculate_billing_run",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async finaliseBilling(
    access_token: string,
    billing_run_id: number,
    finalise: boolean = true
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        billing_run_id,
        finalise,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/calculate_billing_run",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async exportBillingRun(
    access_token: string,
    billing_run_id: number,
    billing_org_ids: string,
    export_mode: BillingExportMode
  ): Promise<exportBillingRunResult | any> {
    try {
      const request = JSON.stringify({
        billing_run_id,
        billing_org_ids,
        export_mode,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/export_billing_run",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as exportBillingRunResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async addProductOrg(
    access_token: string,
    org_id: number,
    billing_rate_id: number
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        org_id,
        billing_rate_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_product_orgs",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async registerUser(
    contact_number: string,
    email: string,
    country: string,
    company_name: string,
    first_name: string,
    last_name: string,
    password: string,
    ts_and_cs: boolean,
    hear_about_us: string
  ): Promise<registrationResult | any> {
    try {
      const request = JSON.stringify({
        contact_number,
        email,
        country,
        company_name,
        first_name,
        last_name,
        password,
        ts_and_cs,
        hear_about_us,
      });
      const res = fetch(FunctionService.ManageRestApiRoot + "/register", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as registrationResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async addOrgToSentinelProduct(
    access_token: string,
    org_id: number,
    billing_rate_id: number,

    name: string,
    met: { [key: string]: any }
  ): Promise<addBillingOrgResult | any> {
    try {
      let meta = JSON.stringify(met);
      const request = JSON.stringify({
        org_id,
        billing_rate_id,
        name,
        meta,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_sentinel",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async addOrgToImmixProduct(
    access_token: string,
    org_id: number,
    billing_rate_id: number,
    locale: string,
    timezone_str: string
  ): Promise<addOrgToImmixResult | any> {
    try {
      const request = JSON.stringify({
        org_id,
        billing_rate_id,
        locale,
        timezone_str,
      });
      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_immix", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as addOrgToImmixResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async updateOrginInSentinel(
    access_token: string,
    org_id: number,

    met: { [key: string]: any }
  ): Promise<addBillingOrgResult | any> {
    try {
      let meta = JSON.stringify(met);
      const request = JSON.stringify({
        org_id,
        meta,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_sentinel",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getImmix(
    access_token: string,
    org_id: number
  ): Promise<addOrgToImmixResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_immix?org_id=" + org_id,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as addOrgToImmixResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getSentinelToken(
    access_token: string,
    org_id: number
  ): Promise<GetSentinelResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_sentinel?org_id=" + org_id,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as GetSentinelResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteProductOrg(
    access_token: string,
    org_id: number,
    billing_rate_id: number
  ): Promise<addBillingOrgResult | any> {
    try {
      const request = JSON.stringify({
        org_id,
        billing_rate_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_product_orgs",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as addBillingOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createAlertEndPoint(
    access_token: string,
    alert_channel_id: number,
    end_point_type: string,
    name: string,
    meta: { [key: string]: any }
  ): Promise<CreateAlertEndPointResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
        end_point_type: end_point_type,
        name: name,
        meta: meta,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_end_point",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateAlertEndPointResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createAlertEndPointForHikCentral(
    access_token: string,
    alert_channel_id: number,
    end_point_type: string,
    meta: { [key: string]: any }
  ): Promise<CreateAlertEndPointResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
        end_point_type: end_point_type,
        meta: meta,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_end_point",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateAlertEndPointResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async listHikCentralEntities(
    access_token: string,
    alert_channel_end_point_id: number,
    entity_type: string,
    pageNo: number,
    pageSize: number
  ): Promise<ListHikCentralEntitiesResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_end_point_id: alert_channel_end_point_id,
        entity_type: entity_type,
        pageNo: pageNo,
        pageSize: pageSize,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_hikcentral_entities",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as ListHikCentralEntitiesResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async configureHikCentralEventRules(
    access_token: string,
    alert_channel_end_point_id: number
  ): Promise<CreateUserAlertChannelResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_end_point_id: alert_channel_end_point_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/configure_hikcentral_rules",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateUserAlertChannelResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createHub(
    access_token: string,
    org_id: number,
    hub_id_str: string,
    location: string,
    site_id: number
  ): Promise<CreateHubResult | any> {
    try {
      var request;
      if (!site_id && site_id === 0) {
        request = JSON.stringify({
          org_id: org_id,
          hub_id_str: hub_id_str,
          location: location,
        });
      } else {
        request = JSON.stringify({
          org_id: org_id,
          hub_id_str: hub_id_str,
          location: location,
          site_id: site_id,
        });
      }

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_hub", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as CreateHubResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async createOrg(
    access_token: string,
    parent_org_id: number,
    org_name: string,
    logo_path: string
  ): Promise<CreateOrgResult | any> {
    try {
      const request = JSON.stringify({
        parent_org_id: parent_org_id,
        org_name: org_name,
        logo_path: logo_path,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_org", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as CreateOrgResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createReportGroup(
    access_token: string,
    org_id: number,
    name: string,
    recipients: string[]
  ): Promise<CreateReportGroupResult | any> {
    try {
      var strrecipients = "";
      recipients.forEach((recipient) => {
        strrecipients = strrecipients + recipient + ",";
      });
      strrecipients = strrecipients.substring(0, strrecipients.length - 1);
      const request = JSON.stringify({
        org_id: org_id,
        name: name,
        recipients: strrecipients,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_report_group",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateReportGroupResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createSensor(
    access_token: string,
    hub_id: number,
    site_id: number,
    human_name: string,
    config: string,
    current_env: string = "None"
  ): Promise<CreateSensorResult | any> {
    try {
      const request = JSON.stringify({
        hub_id: hub_id,
        site_id: site_id,
        human_name: human_name,
        config: config,
        environment: current_env,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_sensor", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as CreateSensorResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  // the fetch method createNewSmtpPassword is used on for an SMTP EdgePlus premium site.
  // the fetch method createNewSmtpPassword, is used to either view current password for an SMTP site or change the password
  // for viewing the password, the argument generate_password must be set to false
  // for changing the password, the argument generate_password must be set to false
  // the access token is the auth token used to authenticate user generally inside the whole app functionality

  async createNewSmtpPassword(
    access_token: string,
    site_id: number,
    generate_password: boolean
  ): Promise<CreateNewSmtpPasswordResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        generate_password: generate_password,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/edge_enhanced_smtp",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateNewSmtpPasswordResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async fetchServerSnapshots(
    access_token: string,
    sensor_id: any,
    endTime?: any,
    maxCount?: number
  ): Promise<any> {
    try {
      const currentTimeSeconds = endTime || Math.floor(Date.now() / 1000);
      const sevenDaysAgoSeconds = currentTimeSeconds - 7 * 24 * 60 * 60;

      let request;
      if (!endTime) {
        request = JSON.stringify({
          max_count: maxCount || 5,
          sensor_id: sensor_id,
          start_time_filter: sevenDaysAgoSeconds,
          end_time_filter: currentTimeSeconds,
        });
      } else {
        request = JSON.stringify({
          max_count: maxCount || 5,
          sensor_id: sensor_id,
          end_time_filter: endTime,
        });
      }

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/server_multi_snapshot",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateNewSmtpPasswordResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createSensorSimple(
    access_token: string,
    hub_id: number,
    site_id: number,
    human_name: string,
    environment: string = "None"
  ): Promise<CreateSensorResult | any> {
    try {
      const request = JSON.stringify({
        hub_id: hub_id,
        site_id: site_id,
        human_name: human_name,
        environment: environment,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_sensor", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as CreateSensorResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async createSite(
    access_token: string,
    org_id: number,
    site_name: string,
    org_billing_rate_id: number,
    training: boolean,
    training_fraction: number,
    frontelOrg: boolean,
    ajaxOrg: boolean,
    ajaxHubId: string
  ): Promise<CreateSiteResult | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        site_name: site_name,
        org_billing_rate_id: org_billing_rate_id,
        training: training,
        training_fraction: training_fraction,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as CreateSiteResult;
      if (result.success === true && frontelOrg) {
        await this.activateFrontelSite(access_token, result.site_id);
      }
      if (result.success === true && ajaxOrg) {
        await this.activateAjaxSite(access_token, result.site_id, ajaxHubId);
      }
      return result;
    } catch (err) {
      return err;
    }
  }
  async createSiteLogicalHub(
    access_token: string,
    org_id: number,
    site_name: string,
    org_billing_rate_id: number,
    frontelOrg: boolean,
    ajaxOrg: boolean,
    ajaxHubId: string,
    timezone: string
  ): Promise<CreateSiteResult | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        site_name: site_name,
        org_billing_rate_id: org_billing_rate_id,
        timezone_str: timezone,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/add_api_site", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as CreateSiteResult;
      if (result.success === true && frontelOrg) {
        await this.activateFrontelSite(access_token, result.site_id);
      }
      if (result.success === true && ajaxOrg) {
        await this.activateAjaxSite(access_token, result.site_id, ajaxHubId);
      }
      return result;
    } catch (err) {
      return false;
    }
  }
  async createSiteReport(
    access_token: string,
    site_id: number,
    report_group_id: number,
    report_type: string,
    daily_utc_schedule: string
  ): Promise<CreateSiteReportResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        report_group_id: report_group_id,
        report_type: report_type,
        daily_utc_schedule: daily_utc_schedule,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_site_reporting",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as CreateSiteReportResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createUser(
    access_token: string,
    entry_org_id: number,
    username: string,
    first_name: string,
    last_name: string,
    password: string,
    email: string,
    contact_number: string,
    roles: string,
    web_roles: string,
    job_description: string
  ): Promise<CreateUserResult | any> {
    try {
      const request = JSON.stringify({
        entry_org_id: entry_org_id,
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        contact_number: contact_number,
        roles,
        web_roles,
        job_description,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_user", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as CreateUserResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async createUserAlertChannelEndPoint(
    access_token: string,
    alert_channel_end_point_id: number,
    user_id: number,
    end_point_role_names: string
  ): Promise<CreateUserAlertChannelResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_end_point_id: alert_channel_end_point_id,
        user_id: user_id,
        end_point_role_names: end_point_role_names,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/user_end_point", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as CreateUserAlertChannelResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  //####################
  //# UPDATE FUNCTIONS #
  //####################

  async setArm(
    access_token: string,
    site_ids: number[],
    action: string,
    mute_mins: number
  ): Promise<any> {
    //action is either "mute" or "unmute"
    try {
      let site_id_filter = "[";
      site_ids.forEach((alert_channel_id) => {
        site_id_filter = site_id_filter + alert_channel_id + ",";
      });
      site_id_filter =
        site_id_filter.substr(0, site_id_filter.length - 1) + "]";
      let request = "";
      if (mute_mins != 0) {
        request = JSON.stringify({
          site_ids: site_id_filter,
          mute_mins: mute_mins,
          action: action,
        });
      } else {
        request = JSON.stringify({
          site_ids: site_id_filter,
          action: action,
        });
      }
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/site_sensor_arm_control",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      let muteresult = (await (await res).json()) as string;
      return muteresult;
    } catch (err) {
      return err;
    }
  }

  // This is used for creation as well as it is a modification on user "entry point set"
  async modifyUserEntryPoint(
    access_token: string,
    entry_org_id: number,
    user_id: number,
    roles: string
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        entry_org_id: entry_org_id,
        user_id: user_id,
        roles: roles,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_user", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifyAlertChannel(
    access_token: string,
    alert_channel_id: number,
    site_id: number,
    alert_channel_name: string,
    // default_confidence_threshold: number,
    meta: { [key: string]: any },
    alert_time_zone: string
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        alert_channel_id: alert_channel_id,
        alert_channel_name: alert_channel_name,
        // default_confidence_threshold: default_confidence_threshold,
        meta: meta,
        timezone_str: alert_time_zone,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_alert_channel",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async modifyAlertChannelForMotionCam(
    access_token: string,
    alert_channel_id: number,
    site_id: number,
    alert_channel_name: string,
    default_confidence_threshold: number,
    meta: { [key: string]: any },
    alert_time_zone: string
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        alert_channel_id: alert_channel_id,
        alert_channel_name: alert_channel_name,
        default_confidence_threshold: default_confidence_threshold,
        meta: meta,
        timezone_str: alert_time_zone,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_alert_channel",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifyAlertEndPoint(
    access_token: string,
    alert_channel_end_point_id: number,
    meta: { [key: string]: any }
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_end_point_id: alert_channel_end_point_id,
        meta: meta,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_end_point",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifyAlertRule(
    access_token: string,
    alert_channel_id: number,
    alert_config_ids: string,
    valid_days_and_times: string
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
        alert_config_ids: alert_config_ids,
        valid_days_and_times: valid_days_and_times,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_alert_config",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async modifyHub(
    access_token: string,
    hub_id: number,
    location: string,
    site_id: number,
    is_shared: boolean
  ): Promise<UpdateResult | any> {
    try {
      let request = "";
      if (is_shared) {
        request = JSON.stringify({
          hub_id: hub_id,
          location: location,
          is_shared: is_shared,
        });
      } else {
        request = JSON.stringify({
          hub_id: hub_id,
          location: location,
          is_shared: is_shared,
          site_id: site_id,
        });
      }

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_hub", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async modifyHubNetwork(
    access_token: string,
    hub_id: number,
    network_config: NetworkConfig
  ): Promise<UpdateResult | any> {
    try {
      let strnetwork_config = JSON.stringify(network_config);
      const request = JSON.stringify({
        hub_id: hub_id,
        network_config: strnetwork_config,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/network_config", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async modifySensorMeta(
    access_token: string,
    sensor_id: number,
    met: { [key: string]: any }
  ): Promise<UpdateSensorResult | any> {
    try {
      let meta = JSON.stringify(met);
      const request = JSON.stringify({
        sensor_id: sensor_id,
        meta,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_sensor", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateSensorResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifyBatchSensorMeta(
    access_token: string,
    sensor_data: { [key: string]: any }[]
  ): Promise<UpdateSensorResult | any> {
    try {
      const request = JSON.stringify({
        sensor_data: JSON.stringify(sensor_data),
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/batch_sensors", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateSensorResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async fetchTalkbackRelay(
    access_token: string,
    org_id: number
  ): Promise<TalkbackRelay | any> {
    try {
      const request = JSON.stringify({
        org_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/talkback_relay?org_id=" + org_id,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = (await (await res).json()) as TalkbackRelay;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySensorSMTP(
    access_token: string,
    sensor_id: number,
    human_name: string,
    current_env: string
  ): Promise<UpdateSensorResult | any> {
    try {
      const request = JSON.stringify({
        sensor_id: sensor_id,
        human_name: human_name,
        environment: current_env,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_sensor", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateSensorResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySensor(
    access_token: string,
    sensor_id: number,
    human_name: string,
    config: string,
    snapshot: string,
    current_env: string = "None",
    standardConfig?: any
  ): Promise<UpdateSensorResult | any> {
    try {
      let request;
      if (standardConfig) {
        request = JSON.stringify({
          sensor_id: sensor_id,
          human_name: human_name,
          config: config,
          standard_image: snapshot,
          environment: current_env,
          motion_exclusion_zones: standardConfig.motion_exclusion_zones,
          override_exclusion_zones_1: standardConfig.override_exclusion_zones_1,
          override_exclusion_zones_2: standardConfig.override_exclusion_zones_2,
        });
      } else {
        request = JSON.stringify({
          sensor_id: sensor_id,
          human_name: human_name,
          config: config,
          standard_image: snapshot,
          environment: current_env,
        });
      }

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_sensor", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateSensorResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySensorEnabled(
    access_token: string,
    sensor_id: number,
    enabled: boolean,
    reason?: string | "Not provided"
  ): Promise<UpdateSensorResult | any> {
    try {
      const request = JSON.stringify({
        sensor_id: sensor_id,
        enabled: enabled,
        reason: reason,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_sensor", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateSensorResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySiteEnabled(
    access_token: string,
    site_id: number,
    enabled: boolean,
    reason?: string | "Not provided"
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        enabled: enabled,
        reason: reason,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySiteUseEmailTime(
    access_token: string,
    site_id: number,
    use_email_time: boolean
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id,
        use_email_time,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySimpleSensor(
    access_token: string,
    sensor_id: number,
    human_name: string,
    enabled: boolean,
    current_env: string
  ): Promise<UpdateSensorResult | any> {
    try {
      const request = JSON.stringify({
        sensor_id: sensor_id,
        human_name: human_name,
        enabled: enabled,
        environment: current_env,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_sensor", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateSensorResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySiteReport(
    access_token: string,
    site_reporting_id: number,
    daily_utc_schedule: string
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_reporting_id: site_reporting_id,
        daily_utc_schedule: daily_utc_schedule,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/site_report", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySite(
    access_token: string,
    site_id: number,
    name: string,
    training_fraction: number,
    training_triggers_only: boolean
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        site_name: name,
        fraction: Number(training_fraction),
        triggers_only: training_triggers_only,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySiteTrainingSettings(
    access_token: string,
    site_id: number,
    training_fraction: number,
    training_triggers_only: boolean
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        fraction: Number(training_fraction),
        triggers_only: training_triggers_only,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySiteName(
    access_token: string,
    site_id: number,
    name: string
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        site_name: name,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifySiteProduct(
    access_token: string,
    site_id: number,
    billing_rate_id: number
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        billing_rate_id: billing_rate_id,
      });
      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifyReportGroup(
    access_token: string,
    report_group_id: number,
    name: string,
    recipients: string[]
  ): Promise<UpdateResult | any> {
    try {
      var strrecipients = "";
      recipients.forEach((recipient) => {
        strrecipients = strrecipients + recipient + ",";
      });
      strrecipients = strrecipients.substring(0, strrecipients.length - 1);
      const request = JSON.stringify({
        report_group_id: report_group_id,
        name: name,
        recipients: strrecipients,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_report_group",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifyPasswordSelf(
    access_token: string,
    old_password: string,
    password: string
  ): Promise<UpdateResult | any> {
    try {
      let request = JSON.stringify({
        old_password: old_password,
        password: password,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_self", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifyPasswordAdmin(
    access_token: string,
    user_id: number,
    admin_password: string,
    password: string
  ): Promise<UpdateResult | any> {
    try {
      let request = JSON.stringify({
        user_id: user_id,
        admin_password: admin_password,
        password: password,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_user", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async modifyUserSelf(
    access_token: string,
    first_name: string,
    last_name: string,
    email: string,
    contact_number: string
  ): Promise<UpdateResult | any> {
    try {
      let request = JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        contact_number: contact_number,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_self", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async deactivateMFA(
    access_token: string,
    totp_enabled: boolean
  ): Promise<UpdateResult | any> {
    try {
      let request = JSON.stringify({
        totp_enabled: totp_enabled,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/disable_totp", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async modifyUserAdmin(
    access_token: string,
    user_id: number,
    first_name: string,
    last_name: string,
    email: string,
    contact_number: string,
    username: string,
    enabled: boolean,
    roles: string,
    web_roles: string,
    job_description: string,
    entry_org_id: number
  ): Promise<UpdateResult | any> {
    try {
      let request = JSON.stringify({
        user_id: user_id,
        first_name: first_name,
        last_name: last_name,
        email: email,
        contact_number: contact_number,
        username: username,
        enabled: enabled,
        roles,
        web_roles: web_roles ? web_roles : null,
        job_description,
        entry_org_id,
      });
      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_user", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async modifyUserGroup(
    access_token: string,
    user_id: number,
    current_entry_org_id: number,
    new_entry_org_id: number,
    roles: string,
    job_description: string
  ): Promise<UpdateResult | any> {
    try {
      let request = JSON.stringify({
        user_id: user_id,
        roles,
        job_description,
        new_entry_org_id,
        current_entry_org_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_user_group",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  //####################
  //# MOVE FUNCTIONS   #
  //####################

  async moveOrg(
    access_token: string,
    org_id: number,
    parent_org_id: number
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
        parent_org_id: parent_org_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/move_org", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async moveSite(
    access_token: string,
    site_id: number,
    parent_org_id: number
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        org_id: parent_org_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/move_site", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async moveSharedHub(
    access_token: string,
    hub_id: number,
    parent_org_id: number
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        hub_id: hub_id,
        org_id: parent_org_id,
        is_shared: true,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_hub", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  //####################
  //# DELETE FUNCTIONS #
  //####################
  async deleteAlertChannel(
    access_token: string,
    alert_channel_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_alert_channel",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteAlertUser(
    access_token: string,
    user_id: number,
    alert_channel_end_point_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_end_point_id: alert_channel_end_point_id,
        user_id: user_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/user_end_point", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteUserWebOrgs(
    access_token: string,
    user_id: number,
    org_ids: string
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        org_ids: org_ids,
        user_id: user_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/user_web_orgs", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteEndPoint(
    access_token: string,
    alert_channel_end_point_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_end_point_id: alert_channel_end_point_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_end_point",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteEndPointUser(
    access_token: string,
    alert_channel_end_point_id: number,
    user_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        alert_channel_end_point_id: alert_channel_end_point_id,
        user_id: user_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/user_end_point", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteRules(
    access_token: string,
    config_ids: number[],
    alert_channel_id: number
  ): Promise<DeleteResult | any> {
    try {
      let str_config_ids = "[";
      config_ids.forEach((config_id) => {
        str_config_ids = str_config_ids + config_id + ",";
      });
      str_config_ids =
        str_config_ids.substring(0, str_config_ids.length - 1) + "]";
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
        alert_config_ids: str_config_ids,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_alert_config",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteSiteReport(
    access_token: string,
    site_reporting_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        site_reporting_id: site_reporting_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_site_reporting",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteUserEntryPoint(
    access_token: string,
    entry_org_id: number,
    user_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        entry_org_id: entry_org_id,
        user_id: user_id,
        roles: "",
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_user", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async editOrg(
    access_token: string,
    org_id: number,
    org_name: string
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        org_id,
        org_name,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_org", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteOrg(
    access_token: string,
    org_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        org_id: org_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_org", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async EnableOrg(
    access_token: string,
    org_id: number,
    enabled: boolean
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        org_id,
        enabled,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/enable_disable_org",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteHub(
    access_token: string,
    hub_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        hub_id: hub_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_hub", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteReportGroup(
    access_token: string,
    report_group_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        report_group_id: report_group_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_report_group",
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        }
      );
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteSensor(
    access_token: string,
    sensor_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        sensor_id: sensor_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_sensor", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async deleteSite(
    access_token: string,
    site_id: number
  ): Promise<DeleteResult | any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as DeleteResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  //####################
  //# UTIL FUNCTIONS   #
  //####################

  async forceHubUpdate(
    access_token: string,
    hub_id: number
  ): Promise<GetResult | any> {
    try {
      const request = JSON.stringify({
        hub_id: hub_id,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/force_config", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetResult;
      return result;
    } catch (err) {
      return err;
    }
  }
  async forceHubRestart(
    access_token: string,
    hub_id: number
  ): Promise<GetResult | any> {
    try {
      const request = JSON.stringify({
        hub_id: hub_id,
      });
      const res = fetch(FunctionService.ManageRestApiRoot + "/force_restart", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async getTranslations(access_token: string, locale: string) {
    if (this.locales && this.locales.length > 0) {
      let localemap = this.locales.find((i) => i.locale === locale);
      if (!localemap) {
        const locales: Locale[] = await this.getLocale(access_token, locale);
        if (locales && locales.length > 0) {
          locales.forEach((localemapinstance) => {
            this.locales.push(localemapinstance);
          });
        }
      }
    }
  }

  translate(locale: string, element: string, key: string): string {
    if (!this.locales || (this.locales && this.locales.length === 0)) {
      this.updateLocale();
    }
    if (this.locales) {
      let localemap = this.locales.find(
        (i) => i.locale === locale && i.element === element
      );
      if (localemap) {
        const map = localemap.mapping;
        if (map.hasOwnProperty(key)) {
          return map[key];
        } else {
          return key;
        }
      } else {
        return key;
      }
    } else {
      return key;
    }
  }
  async updateLocale(): Promise<void> {
    if (this.authService) {
      var token = await this.authService.getAuthorisedToken();
      if (token) {
        var newlocales = await this.getLocale(token, "en_ZA");
        if (newlocales) {
          this.locales = newlocales;
        }
      }
    }
  }
  public getOrgFromTree(org_id: number, orgs: Org[]): any {
    var result;
    for (let key in orgs) {
      let org = orgs[key];
      if (Number(key) === org_id) {
        result = org;
        break;
      } else {
        result = this.getOrgFromTree(org_id, org.orgs);
      }
      if (result) {
        return result;
      }
    }
    return result;
  }
  setAuthService(authSerevice: AuthService) {
    this.authService = authSerevice;
  }

  public formatDate(date: Date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var strTime = hours + ":" + minutes + ":" + seconds;
    return (
      date.getFullYear() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getDate() +
      "  " +
      strTime
    );
  }

  async setHd(
    token: string,
    hd_toggle: string,
    sensor_id: number
  ): Promise<any> {
    if (token) {
      try {
        const request = JSON.stringify({
          hd_toggle: hd_toggle,
          sensor_id: sensor_id,
          verbose: false,
          paginate: false,
        });

        const res = fetch(FunctionService.ManageRestApiRoot + "/set_hd", {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = (await (await res).json()) as any;
        return result.success;
      } catch (err) {
        return err;
      }
    }
  }

  async checkRuleType(token: string, sensor_id: number): Promise<any> {
    if (token) {
      try {
        const request = JSON.stringify({
          sensor_id: sensor_id,
        });

        const res = fetch(
          FunctionService.ManageRestApiRoot + "/check_advanced_alert_config",
          {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          }
        );
        const result = (await (await res).json()) as any;
        return result;
      } catch (err) {
        return err;
      }
    }
  }

  async setAdvancedToggle(
    token: string,
    advanced_rules_enabled: boolean,
    sensor_id: number
  ): Promise<any> {
    if (token) {
      try {
        const request = JSON.stringify({
          advanced_rules_enabled: advanced_rules_enabled,
          sensor_id: sensor_id,
        });

        const res = fetch(
          FunctionService.ManageRestApiRoot + "/manage_advanced_rules_enabled",
          {
            method: "put",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          }
        );
        const result = (await (await res).json()) as any;
        return result.success;
      } catch (err) {
        return err;
      }
    }
  }

  // async fetchDynDnsConfiguration(
  //   token: string,
  //   siteOrSensorIndicator: string,
  //   siteOrSensorId: number
  // ): Promise<dynDnsConfigurationResult> {
  //   try {
  //     let requiredDynDns = {};
  //     if (siteOrSensorIndicator === "site_id") {
  //       requiredDynDns = {
  //         site_id: siteOrSensorId,
  //       };
  //     } else if (siteOrSensorIndicator === "sensor_id") {
  //       requiredDynDns = {
  //         sensor_id: siteOrSensorId,
  //       };
  //     }
  //
  //     const res = fetch(FunctionService.ManageRestApiRoot + "/ddns", {
  //       method: "post",
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: "Bearer " + token,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requiredDynDns),
  //     });
  //     const result = await (await res).json();
  //     result.code = (await res).status;
  //     return result as dynDnsConfigurationResult;
  //   } catch (err) {
  //     return err;
  //   }
  // }

  async requestDynDnsActivation(
    token: string,
    siteId: number,
    emailAddress: string
  ): Promise<any> {
    try {
      let requiredDynDns = {
        site_id: siteId,
        email_address: emailAddress,
      };
      const res = fetch(FunctionService.ManageRestApiRoot + "/request_ddns", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requiredDynDns),
      });
      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err) {
      return err;
    }
  }

  async activateDynDns(
    token: string,
    siteId: number,
    dynDnsDomain: string,
    dynDnsUsername: string,
    dynDnsPassword: string
  ): Promise<any> {
    try {
      let activateDynDns = {
        site_id: siteId,
        active_dyn_dns: true,
        request_dyn_dns: true,
        dyn_dns_domain: dynDnsDomain,
        dyn_dns_username: dynDnsUsername,
        dyn_dns_password: dynDnsPassword,
      };

      const res = fetch(FunctionService.ManageRestApiRoot + "/manage_site", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(activateDynDns),
      });
      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err) {
      return err;
    }
  }

  // TODO: JASON
  async activateFrontelOrg(
    token: string,
    orgId: number,
    frontelDomain: string
    // frontelConnectionPort: number,
    // useModifiedFrontel: boolean
  ): Promise<any> {
    try {
      let activateFrontel = {
        org_id: orgId,
        frontel_ip: frontelDomain,
        // frontel_port: frontelConnectionPort,
        // use_account: useModifiedFrontel,
      };

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_frontel_org",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(activateFrontel),
        }
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err) {
      return err;
    }
  }

  async activateFrontelSite(token: string, siteId: number): Promise<any> {
    try {
      let activateFrontel = {
        site_id: siteId,
      };

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_frontel_site",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(activateFrontel),
        }
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err) {
      return err;
    }
  }

  async activateAjaxSite(
    token: string,
    siteId: number,
    ajaxHubId: string
  ): Promise<any> {
    try {
      let activateFrontel = {
        site_id: siteId,
        ajax_hub_id: ajaxHubId,
      };

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_ajax_site",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(activateFrontel),
        }
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err) {
      return err;
    }
  }

  async fetchAjaxStatus(
    token: string,
    siteId: number,
    ajaxHubId: string
  ): Promise<FetchAjaxHubStatus | string> {
    try {
      let activateFrontel = {
        site_id: siteId,
        ajax_hub_id: ajaxHubId,
      };

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/fetch_ajax_status",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(activateFrontel),
        }
      );
      const result = await (await res).json();
      result.code = (await res).status;
      if (result.code === 200) {
        return result as FetchAjaxHubStatus;
      } else {
        return result.msg;
      }
    } catch (err) {
      return "Error encountered in fetching Ajax hub status";
    }
  }

  async handleFrontelArming(
    token: string,
    frontelAccount: string,
    frontelPanel: string,
    status: number,
    time?: number
  ): Promise<any> {
    try {
      let triggerCommand = {};
      if (time !== undefined) {
        triggerCommand = {
          frontel_account: frontelAccount,
          panel_serial_number: frontelPanel,
          status: status,
          time: time,
        };
      } else {
        triggerCommand = {
          frontel_account: frontelAccount,
          panel_serial_number: frontelPanel,
          status: status,
        };
      }

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_frontel_arm",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(triggerCommand),
        }
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err) {
      return err;
    }
  }

  async handleFrontelDisconnect(
    token: string,
    frontelAccount: string,
    frontelPanel: string
  ): Promise<any> {
    try {
      let triggerCommand = {
        frontel_account: frontelAccount,
        panel_serial_number: frontelPanel,
      };

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/frontel_panel_disconnect",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(triggerCommand),
        }
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err) {
      return err;
    }
  }

  async getListenerDeviceNum(access_token: string): Promise<any> {
    // TODO: JASON adapt endpoint to accept argument of site_id for frontel integration
    // let appendment = ""
    // if (typeof siteId === "number") {
    //   appendment = "?site_id=" + siteId
    // }
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/create_listener_number", // + appendment,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const result = await (await res).json();
      result.code = (await res).status;
      if (result.code === 200) {
        return result.device_num;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  // TODO: JASON
  // async fetchNextFrontelPort(
  //   access_token: string,
  //   orgId: number
  // ): Promise<
  //   | { port: number; assigned: boolean; ip: string; useAccount: boolean }
  //   | string
  // > {
  //   try {
  //     let request = {
  //       org_id: orgId,
  //     };
  //     const res = fetch(
  //       FunctionService.ManageRestApiRoot + "/fetch_frontel_port",
  //       {
  //         method: "post",
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: "Bearer " + access_token,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(request),
  //       }
  //     );
  //     const result = await (await res).json();
  //     if (result.success === true) {
  //       return {
  //         port: result.port as number,
  //         assigned: result.assigned as boolean,
  //         ip: result.ip as string,
  //         useAccount: result.use_account as boolean,
  //       };
  //     } else {
  //       return result.msg as string;
  //     }
  //   } catch (err) {
  //     return "Error in fetching port, please contact DeepAlert Support";
  //   }
  // }

  // Ajax methods
  async getAjaxRequests(access_token: string): Promise<AjaxRequests | any> {
    try {
      const res = fetch(FunctionService.ManageRestApiRoot + "/ajax_monitor", {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
      });
      const result = await (await res).json();
      return result as AjaxRequests;
    } catch (err) {
      return err;
    }
  }

  async sendAjaxRequests(access_token: string, request: string): Promise<any> {
    let req = {
      ajax_mapping: request,
    };
    try {
      const res = fetch(FunctionService.ManageRestApiRoot + "/ajax_monitor", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
      });
      const result = await (await res).json();
      return result;
    } catch (err) {
      return err;
    }
  }
}
